import { Container } from '@material-ui/core';
import React from 'react';

interface FormLayoutProps {
  title?: string;
}

export const FormLayout: React.FunctionComponent<FormLayoutProps> = ({ children, title }) => (
  <Container maxWidth="md">
    {title ? <h1>{title}</h1> : null}
    {children}
  </Container>
);
