export type Size = [string, string | [number, number]];

export const sizes = [
  ['Fluid', 'fluid'],
  ['Custom', [1, 1]],
  ['Micro Bar', [88, 31]],
  ['MMA Small Banner', [120, 20]],
  ['MMA Small Banner', [120, 30]],
  ['Button 2', [120, 60]],
  ['Button 1', [120, 90]],
  ['Vertical Banner', [120, 240]],
  ['Skyscraper', [120, 600]],
  ['Button', [125, 125]],
  ['Wide Skyscraper', [160, 600]],
  ['MMA Medium Banner', [168, 28]],
  ['MMA Medium Banner', [168, 42]],
  ['Rectangle', [180, 150]],
  ['Small Square', [200, 200]],
  ['Custom', [200, 446]],
  ['MMA Large Banner', [216, 36]],
  ['MMA Large Banner', [216, 54]],
  ['Custom', [220, 90]],
  ['Half Banner', [234, 60]],
  ['Custom', [240, 133]],
  ['Vertical Rectangle', [240, 400]],
  ['Custom', [250, 60]],
  ['Square', [250, 250]],
  ['Custom', [250, 300]],
  ['Custom', [250, 360]],
  ['Custom', [292, 30]],
  ['Custom', [300, 31]],
  ['MMA Extra Large Banner', [300, 50]],
  ['MMA Extra Large Banner', [300, 75]],
  ['3:1 Rectangle', [300, 100]],
  ['Medium Rectangle', [300, 250]],
  ['Half Page Ad', [300, 600]],
  ['Custom', [300, 1050]],
  ['Mobile Leaderboard', [320, 50]],
  ['Custom', [320, 100]],
  ['Mobile Portrait Full Screen', [320, 480]],
  ['Large Rectangle', [336, 280]],
  ['Full Banner', [468, 60]],
  ['Mobile Landscape Full Screen', [480, 320]],
  ['Custom', [580, 400]],
  ['Leaderboard', [728, 90]],
  ['Custom', [750, 100]],
  ['Custom', [750, 200]],
  ['Custom', [750, 300]],
  ['Tablet Portrait Full Screen', [768, 1024]],
  ['Custom', [930, 180]],
  ['Custom', [950, 90]],
  ['Custom', [960, 90]],
  ['Custom', [970, 66]],
  ['Large Leaderboard', [970, 90]],
  ['Custom', [970, 250]],
  ['Custom', [980, 90]],
  ['Custom', [980, 120]],
  ['Tablet Landscape Full Screen', [1024, 768]],
].map((size) => {
  if (typeof size[1] === 'string') {
    return size;
  }

  return [`${size[1][0]}x${size[1][1]} (${size[0]})`, size[1]];
});
