import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    publisherId: yup.string().required(),
    tagId: yup.string().required(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
  }),
};

export const sovrn: BidderAdapter = {
  name: 'sovrn',
  requirements,
  overallRequirements: ['publisherId'],
};
