export const objectTyped = {
  /**
   * Object.keys, but with nice typing (`Array<keyof T>`)
   */
  keys: Object.keys as <T extends Record<string, unknown>>(yourObject: T) => Array<keyof T>,
  /**
   * Object.values, but with nice typing
   */
  values: Object.values as <T extends Record<string, unknown>>(yourObject: T) => Array<T[keyof T]>,
  /**
   * Object.entries, but with nice typing
   */
  entries: Object.entries as <T extends Record<string, unknown>>(
    yourObject: T,
  ) => Array<[keyof T, T[keyof T]]>,
  /**
   * Object.fromEntries, but with nice typing
   */
  fromEntries: Object.fromEntries as <K extends string, V>(
    yourObjectEntries: [K, V][],
  ) => Record<K, V>,
};
