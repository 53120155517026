import { FormControl, TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { useField } from 'formik';

interface ExtraFormFields {
  name: string;
  label: string;
  type: string;
  hasError?: boolean;
  errorMessage?: string;
}

type FormTextFieldProps = Omit<
  Partial<TextFieldProps>,
  'id' | 'value' | 'onChange' | 'error' | 'helperText'
> &
  ExtraFormFields;

export const FormTextField: React.FunctionComponent<FormTextFieldProps> = ({
  name,
  type,
  hasError,
  errorMessage,
  ...rest
}) => {
  const [field, meta] = useField({ name, type });

  return (
    <FormControl className="w-100 mb-3">
      <TextField
        {...rest}
        id={name}
        type={type}
        value={field.value}
        onChange={field.onChange}
        error={hasError || (meta.touched && Boolean(meta.error))}
        helperText={(meta.touched && meta.error) || errorMessage}
        onBlur={field.onBlur}
      />
    </FormControl>
  );
};
