import React from 'react';
import { Button, Menu, MenuItem, PropTypes, Select } from '@material-ui/core';
import { Moment } from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import type { DateOption } from '../date-range-picker';
import { datePickerOptions } from '../date-picker-options';

export interface DateOptionsProps {
  startDate: DateOption;
  endDate: DateOption;
  onSelectOption: (startDate: Moment, endDate: Moment) => void;
  color: PropTypes.Color;
}

export const DateOptions = ({ startDate, endDate, onSelectOption, color }: DateOptionsProps) => {
  // Target for menu mounting/visibility
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // getting the current item via the existing date params
  let selectedItem = datePickerOptions.find(
    (option) =>
      option.start?.isSame(startDate.value, 'day') && option.end?.isSame(endDate.value, 'day'),
  );

  // if nothing matches - it's custom
  if (!selectedItem) {
    [selectedItem] = datePickerOptions;
  }

  // handle clicking the button (opening the menu)
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // handle closing the options menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (option: typeof datePickerOptions[number]) => {
    handleClose();
    if (option.start === null && option.end === null) {
      return;
    }
    startDate.setValue(option.start);
    endDate.setValue(option.end);
    onSelectOption(option.start, option.end);
  };

  return (
    <>
      <Button
        startIcon={<CalendarTodayIcon fontSize="small" />}
        size="small"
        color={color}
        variant="contained"
        onClick={handleButtonClick}
      >
        {selectedItem.label}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {datePickerOptions.map((option) => (
          <MenuItem key={option.label} onClick={() => handleItemClick(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
