import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({}),
  reporting: yup.object({
    google_refresh_token: yup.string().required(),
    network_code: yup.number().integer().required(),
  }),
};

export const google: BidderAdapter = {
  name: 'google',
  requirements,
  overallRequirements: [],
};
