import React from 'react';
import {
  createStyles,
  Drawer,
  List,
  makeStyles,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import type { NavLinkItem } from '../ui-navigation';
import { NavDrawerItem } from '../nav-drawer-item/nav-drawer-item';

interface DrawerStyleProps {
  drawerWidth: number;
}

const useStyles = makeStyles<Theme, DrawerStyleProps>((theme) =>
  createStyles({
    drawer: {
      width: ({ drawerWidth }) => drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: ({ drawerWidth }) => drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    modal: {
      zIndex: theme.zIndex.drawer,
    },
  }),
);

export interface NavDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  drawerWidth: number;
  navItems: NavLinkItem[];
  drawerContent?: JSX.Element | JSX.Element[];
}

export function NavDrawer({ drawerWidth, open, setOpen, navItems, drawerContent }: NavDrawerProps) {
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();
  const aboveLg = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      className={classes.drawer}
      variant={aboveLg ? 'persistent' : 'temporary'}
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
        modal: classes.modal,
      }}
      ModalProps={{
        style: {
          zIndex: theme.zIndex.drawer,
        },
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List dense>
          {navItems.map((navItem) => (
            <NavDrawerItem
              closeDrawer={() => !aboveLg && setOpen(false)}
              key={navItem.label}
              {...navItem}
            />
          ))}
        </List>
        {drawerContent ? <div>{drawerContent}</div> : null}
      </div>
    </Drawer>
  );
}
