import { useCreateSiteMutation, usePublishersQuery } from '@snapshot/data-access';
import React from 'react';
import * as yup from 'yup';
import { FieldMap, FormContainer } from '../forms';

type CreateSiteForm = {
  name: string;
  publisherId: number;
  feedtimeout: number;
};

export const CreateSitePage: React.FunctionComponent = () => {
  const { data: publishersQueryData, loading: publishersQueryLoading } = usePublishersQuery();

  const publishers = publishersQueryData?.publishers || [];

  const [createSite, { loading: createSiteLoading }] = useCreateSiteMutation();

  const fields: FieldMap<CreateSiteForm> = {
    name: {
      validator: yup.string().required(),
      initialValue: '',
      label: 'Name',
      type: 'text',
    },
    publisherId: {
      validator: yup.number().required(),
      initialValue: 0,
      label: 'Publisher',
      type: 'select',
      items: publishers.map((p) => ({
        value: p.id,
        label: p.name,
      })),
    },
    feedtimeout: {
      validator: yup.number().required(),
      initialValue: 800,
      label: 'Feed Timeout',
      type: 'number',
    },
  };

  const handleSubmit = async (values: CreateSiteForm) => {
    await createSite({
      variables: values,
    });
  };

  return (
    <>
      <FormContainer
        submitButtonText="Create Site"
        fields={fields}
        onSubmit={handleSubmit}
        loading={createSiteLoading}
      />
    </>
  );
};
