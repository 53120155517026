/* eslint-disable no-param-reassign */
import {
  GetProfileQuery,
  apolloClient,
  axiosClient,
  GetProfileQueryVariables,
  GetProfileDocument,
} from '@snapshot/data-access';
import axios from 'axios';
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { clearUser, getUser, storeUser } from './storage-adapter';

export type StoredUser = GetProfileQuery['userProfile'];

export interface AuthState {
  authenticated: boolean;
  user: StoredUser | null;
  error: SerializedError | null;
  loading: boolean;
}

interface LoginArgs {
  email: string;
  password: string;
}

type StateWithAuth = unknown & {
  auth: AuthState;
};

const savedUser = getUser();

const initialState: AuthState = {
  authenticated: !!savedUser,
  user: savedUser,
  error: null,
  loading: false,
};

const fetchProfile = async () => {
  const results = await apolloClient.query<GetProfileQuery, GetProfileQueryVariables>({
    query: GetProfileDocument,
    fetchPolicy: 'network-only',
  });

  return results.data.userProfile;
};

export const authCheck = createAsyncThunk<
  StoredUser | null,
  void,
  {
    rejectValue: SerializedError;
  }

  // eslint-disable-next-line consistent-return
>('auth/check', async (_, thunkApi) => {
  try {
    const user = await fetchProfile();
    return user || null;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return thunkApi.rejectWithValue({
        name: e?.name.toString(),
        message: e?.message.toString(),
        code: e?.code?.toString(),
      });
    }
    return thunkApi.rejectWithValue({
      name: (e as Error)?.name.toString(),
      message: (e as Error)?.message.toString(),
    });
  }
});

export const authLogin = createAsyncThunk<
  StoredUser,
  LoginArgs,
  {
    rejectValue: SerializedError;
  }
  // eslint-disable-next-line consistent-return
>('auth/login', async ({ email, password }, thunkApi) => {
  try {
    await axiosClient.post('auth/login', {
      email,
      password,
    });
    const user = await fetchProfile();
    if (!user) {
      throw new Error('No user.');
    }
    return user;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return thunkApi.rejectWithValue({
        name: e?.name.toString(),
        message: e?.message.toString(),
        code: e?.code?.toString(),
      });
    }
    return thunkApi.rejectWithValue({
      name: (e as Error)?.name.toString(),
      message: (e as Error)?.message.toString(),
    });
  } finally {
    // always clear store after login
    await apolloClient.clearStore();
  }
});

export const authLogout = createAsyncThunk<
  void,
  void,
  {
    rejectValue: SerializedError;
  }
>('auth/logout', async (_, thunkApi) => {
  try {
    await axiosClient.post('/auth/logout');
    return;
  } catch (e) {
    // eslint-disable-next-line consistent-return
    if (axios.isAxiosError(e)) {
      thunkApi.rejectWithValue({
        name: e?.name.toString(),
        message: e?.message.toString(),
        code: e?.code?.toString(),
      });
    } else {
      thunkApi.rejectWithValue({
        name: (e as Error)?.name.toString(),
        message: (e as Error)?.message.toString(),
      });
    }
  } finally {
    await apolloClient.clearStore();
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authLogin.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.authenticated = true;
      state.loading = false;
      state.error = null;
      storeUser(payload);
    });
    builder.addCase(authLogin.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
      state.loading = false;
      state.user = null;
      state.authenticated = false;
      clearUser();
    });
    builder.addCase(authLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authLogout.fulfilled, (state) => {
      state.authenticated = false;
      state.error = null;
      state.user = null;
      clearUser();
    });
    builder.addCase(authLogout.rejected, (state) => {
      state.authenticated = false;
      state.error = null;
      state.user = null;
      clearUser();
    });
    builder.addCase(authCheck.fulfilled, (state, { payload }) => {
      if (payload) {
        state.user = payload;
        state.authenticated = true;
        storeUser(payload);
      } else {
        state.user = null;
        state.authenticated = false;
        clearUser();
      }
      state.loading = false;
      state.error = null;
    });
    builder.addCase(authCheck.rejected, (state) => {
      state.error = null;
      state.loading = false;
      state.user = null;
      state.authenticated = false;
      clearUser();
    });
  },
});

export const userSelector = (state: StateWithAuth) => state.auth.user;

export const isAuthenticatedSelector = (state: StateWithAuth) => state.auth.authenticated;

export const { reducer } = authSlice;
