import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  component: Component,
  isAuthenticated,
  children,
  render,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated) {
        if (Component) {
          return <Component {...rest} {...props} />;
        }
        if (children) {
          return children;
        }
        if (render) {
          return render(props);
        }
      }
      return <Redirect to={`/login?path=${props.location.pathname}`} />;
    }}
  />
);
