import React from 'react';
import {
  AppBar,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
      maxWidth: 32,
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h5.fontSize,
    },
  }),
);

/* eslint-disable-next-line */
export interface NavBarProps {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  logo?: {
    src: string;
    alt: string;
  };
  title: string;
  items?: React.ReactElement[];
}

export function NavBar({ drawerOpen, setDrawerOpen, logo, title, items }: NavBarProps) {
  const classes = useStyles();
  return (
    <AppBar color="secondary" position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          onClick={() => setDrawerOpen(!drawerOpen)}
          edge="start"
          color="inherit"
          aria-label="drawer-navigation"
        >
          {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        {logo ? (
          <Link to="/">
            <img className={classes.logo} src={logo.src} alt={logo.alt} />
          </Link>
        ) : null}
        <Typography className={classes.title} color="inherit">
          {title}
        </Typography>
        {items?.map((item) => (
          <React.Fragment key={item.key}>{item}</React.Fragment>
        ))}
      </Toolbar>
    </AppBar>
  );
}
