import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
import { ApolloProvider, from, HttpLink } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
import { apolloClient } from '@snapshot/data-access';
import { authLogout } from './store/authentication/auth.slice';
import { App } from './app';
import { store } from './store';

const httpLink = new HttpLink({
  uri: '/api/graphql',
});

const getErrorLink = (reduxStore: typeof store) =>
  onError(({ graphQLErrors }) => {
    graphQLErrors?.forEach(async ({ extensions }) => {
      if (extensions?.code === 'UNAUTHENTICATED') {
        await reduxStore.dispatch(authLogout());
      }
    });
  });

const setLinks = (reduxStore: typeof store) => {
  apolloClient.setLink(from([getErrorLink(reduxStore), httpLink]));
};

setLinks(store);

moment.defaultFormat = 'YYYY-MM-DD HH:mm:ss';

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </Provider>,
  document.querySelector('#root'),
);
