import { Box, createStyles, makeStyles, TableCell, Theme, Tooltip } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAppDispatch, useAppSelector } from '../../store';
import { DataRow, DataTableContext } from '../../types/datatables';
import { getColumnDefinition, getHeaderDefinition } from '../../utils/helpers/column-definitions';
import { getSort, setSort } from '../../store/data-table/data-table-slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderTop: 'none',
      cursor: 'pointer',
      // whiteSpace: 'nowrap',
    },
  }),
);

interface DataTableHeaderCellProps<R extends DataRow = DataRow, F extends keyof R = keyof R> {
  fieldName: F | string;
  context: DataTableContext;
}

export const DataTableHeaderCell: React.FunctionComponent<DataTableHeaderCellProps> = ({
  fieldName,
  context,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnDefinition = getColumnDefinition(fieldName as any);
  const sort = useAppSelector(getSort);
  const dispatch = useAppDispatch();
  if (!columnDefinition) {
    return null;
  }
  const { headerName, headerProps } = getHeaderDefinition(columnDefinition, context);

  const sortField = columnDefinition.sortField || columnDefinition.key;

  const handleHeaderClick = () => {
    dispatch(
      setSort({
        field: sortField,
      }),
    );
  };

  let sortIcon: JSX.Element | null = null;

  if (sort.field === sortField) {
    if (sort.direction === 'asc') {
      sortIcon = <ArrowDropUpIcon fontSize="small" />;
    } else {
      sortIcon = <ArrowDropDownIcon fontSize="small" />;
    }
  }

  const style: React.CSSProperties = {};

  if (headerProps?.align === 'right') {
    style.marginLeft = 'auto';
  }

  return (
    <TableCell
      className={clsx(
        classes.tableCell,
        {
          grouping: columnDefinition.grouping,
        },
        columnDefinition.grouping?.replace(/ /, '-').toLowerCase().concat('-grouping-header'),
      )}
      align={headerProps?.align}
      onClick={handleHeaderClick}
      style={{
        width: fieldName === 'actions' ? 90 : undefined,
      }}
    >
      <Box display="flex" flex-direction="row">
        {fieldName === 'actions' ? null : (
          <Box
            style={{
              width: 20,
            }}
          >
            {sortIcon}
          </Box>
        )}
        <Tooltip title={columnDefinition.tooltip || ''}>
          <Box style={style}>{headerName}</Box>
        </Tooltip>
      </Box>
    </TableCell>
  );
};
