import React from 'react';
import * as yup from 'yup';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { InputAdornment } from '@material-ui/core';
import type { FieldMapping } from '../form-container/form-container';

const PASSWORD_LENGTH = 8;

export const emailField = (required?: boolean): FieldMapping<string> => {
  let validator = yup.string().email();
  if (required) {
    validator = validator.required();
  }
  return {
    validator,
    initialValue: '',
    label: 'Email',
    type: 'email',
    inputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <MailOutlineTwoToneIcon />
        </InputAdornment>
      ),
    },
  };
};

export const passwordField = (minLength?: boolean): FieldMapping<string> => {
  let validator = yup.string().required();
  if (minLength) {
    validator = validator.min(PASSWORD_LENGTH);
  }
  return {
    validator,
    initialValue: '',
    label: 'Password',
    type: 'password',
    inputProps: {
      startAdornment: (
        <InputAdornment position="start">
          <LockTwoToneIcon />
        </InputAdornment>
      ),
    },
  };
};
