import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    tagId: yup.string().required(),
  }),
  reporting: yup.object({
    api_key: yup.string().required(),
  }),
};

export const adaptmx: BidderAdapter = {
  name: 'adaptmx',
  requirements,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getConnectionValues: (bidder) => {
    const formattedMetadata = JSON.parse(bidder.metadata);
    return {
      tagId: formattedMetadata.tagId,
    };
  },
  overallRequirements: ['tagId'],
};
