import {
  ConnectionBidder,
  useConnectionsPageQuery,
  useCreateBulkConnectionsMutation,
  usePageConnectionsLazyQuery,
} from '@snapshot/data-access';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { adapters } from '@snapshot/bidder-configs';
import { Alert as MUIAlert } from '@material-ui/lab';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import * as yup from 'yup';
import { Formik } from 'formik';
import { LoadingButton } from '../../components/loading-button/loading-button';
import { getBidderAdapter } from '../../utils/helpers/get-bidder-adapter';
import { BidderInputs } from './connections/bidder-inputs';
import { BidderSelector } from './connections/bidder-selector';
import { PlacementRow } from './connections/placement-row';
import { getDynamicValidator } from './connections/dynamic-validator';

export type CBidder = Pick<ConnectionBidder, 'provider' | 'metadata' | 'agencyId' | 'agencyName'>;

interface CreateConnectionError {
  provider: string;
  placementId: number;
  agencyId: number;
}

export interface ConnectionMap {
  // agency id
  [key: string]: {
    // bidder provider
    [key: string]: {
      overallData: Record<string, unknown>;
      metadata: {
        // placement id
        [key: string]: Record<string, unknown>;
      };
    };
  };
}

export interface ConnectionMapErrors {
  [key: string]: {
    // bidder id
    [key: string]: {
      overallData: Record<string, boolean>;
      metadata: {
        // placement id
        [key: string]: Record<string, boolean>;
      };
    };
  };
}

type TParams = {
  id: string;
};

interface UpdateConnection {
  agencyId: number;
  placementId: number;
  provider: string;
  metadata: Record<string, unknown>;
}

const updateConn = (
  connectionMap: ConnectionMap,
  { agencyId, placementId, provider, metadata }: UpdateConnection,
) => {
  // eslint-disable-next-line no-param-reassign
  connectionMap[agencyId] = {
    ...connectionMap?.[agencyId],
    [provider]: {
      ...connectionMap?.[agencyId]?.[provider],
      metadata: {
        ...connectionMap?.[agencyId]?.[provider]?.metadata,
        [placementId]: {
          ...connectionMap?.[agencyId]?.[provider]?.metadata?.[placementId],
          ...metadata,
        },
      },
    },
  };
};

interface UpdateOverallData {
  agencyId: number;
  provider: string;
  overallData: Record<string, unknown>;
}

const updateOverall = (
  connectionMap: ConnectionMap,
  { agencyId, provider, overallData }: UpdateOverallData,
) => {
  // eslint-disable-next-line no-param-reassign
  connectionMap[agencyId] = {
    ...connectionMap?.[agencyId],
    [provider]: {
      ...connectionMap?.[agencyId]?.[provider],
      overallData: {
        ...connectionMap?.[agencyId]?.[provider]?.overallData,
        ...overallData,
      },
    },
  };
};

export const ConnectionsPage: React.FunctionComponent<RouteComponentProps<TParams>> = ({
  match,
}) => {
  const siteId = parseInt(match.params.id, 10);

  // alert
  const [alertText, setAlertText] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('error');
  const [validationSchema, setValidationSchema] = useState(yup.object().shape({}));

  const Alert = (
    <MUIAlert hidden={!alertOpen} severity={alertSeverity} onClose={() => setAlertOpen(false)}>
      {alertText}
    </MUIAlert>
  );

  const { data } = useConnectionsPageQuery({
    variables: {
      siteId,
    },
  });

  // filter out bidders without adapters
  const bidders = (data?.connectionBidders || []).filter((bidder) =>
    adapters.find((adapter) => adapter.name === bidder.provider),
  );

  const [selectedBidders, setSelectedBidders] = useState<CBidder[]>([]);
  const [connectionMap, setConnectionMap] = useState<ConnectionMap>({});
  const [connectionMapErrors, setConnectionMapErrors] = useState<ConnectionMapErrors>({});

  const [getPageConnections] = usePageConnectionsLazyQuery({
    onCompleted: (connectionData) => {
      const overallData: Record<string, unknown> = {};
      connectionData?.connectionsPage?.forEach((c) => {
        const adapter = getBidderAdapter(c.feedProvider);
        if (!adapter) {
          return;
        }
        const overallFields = adapter.overallRequirements;
        const metadata: Record<string, unknown> = {};
        Object.entries(JSON.parse(c.metadata)).forEach(([field, value]) => {
          if (overallFields.includes(field)) {
            overallData[field] = overallData[field] || value || '';
          } else {
            metadata[field] = value;
          }
        });

        updateOverall(connectionMap, {
          agencyId: c.agencyId,
          provider: c.feedProvider,
          overallData,
        });
        updateConn(connectionMap, {
          agencyId: c.agencyId,
          provider: c.feedProvider,
          placementId: c.placementId,
          metadata,
        });
      });

      setConnectionMap({
        ...connectionMap,
      });
    },
  });

  const placements = data?.placements || [];
  const siteName = data?.site?.name;

  const [
    createBulkConnections,
    { loading: createBulkConnectionsLoading },
  ] = useCreateBulkConnectionsMutation();

  // connections

  const handleSelectBidderChange = (sbs: CBidder[], selectedBidder?: CBidder) => {
    setSelectedBidders(sbs);
    if (!sbs.length || !placements) {
      return;
    }
    if (sbs.length > selectedBidders.length && selectedBidder) {
      // we added a bidder so fetch connections for it
      getPageConnections({
        variables: {
          siteId,
          feedProvider: selectedBidder.provider,
          agencyId: selectedBidder.agencyId,
        },
      });
    }
    sbs.forEach((bidder) => {
      const adapter = getBidderAdapter(bidder.provider);
      if (!adapter) {
        return;
      }

      const existingMetadata = adapter.getConnectionValues?.(bidder) || {};
      const overallData: Record<string, unknown> = {};

      const overallFields = adapter.overallRequirements;

      overallFields.forEach((field) => {
        // set overall data to existing values
        if (existingMetadata[field]) {
          overallData[field] = existingMetadata[field];
          // delete from existing metadata
          delete existingMetadata[field];
        }
      });

      const fields = Object.keys(adapter.requirements.connection.fields || {}).filter(
        (field) => !overallFields.includes(field),
      );

      connectionMap[bidder.agencyId] = {
        ...connectionMap[bidder.agencyId],
        [bidder.provider]: {
          overallData,
          metadata: {},
        },
      };

      placements.forEach((placement) => {
        connectionMap[bidder.agencyId][bidder.provider].metadata[placement.id] = {};
        fields.forEach((field) => {
          connectionMap[bidder.agencyId][bidder.provider].metadata[placement.id][field] = '';
        });
      });
    });
    setValidationSchema(
      getDynamicValidator(
        placements.map((p) => p.id),
        sbs.map((b) => ({
          agencyId: b.agencyId,
          provider: b.provider,
        })),
      ),
    );
    setConnectionMap(connectionMap);
  };

  const updateOverallData = (field: string, value: unknown, bidder: CBidder) => {
    connectionMap[bidder.agencyId][bidder.provider].overallData = {
      ...connectionMap[bidder.agencyId][bidder.provider].overallData,
      [field]: value,
    };
    setConnectionMap({ ...connectionMap });
  };

  const updateConnection = (
    metadata: Record<string, unknown>,
    bidder: CBidder,
    placementId: number,
  ) => {
    connectionMap[bidder.agencyId][bidder.provider].metadata[placementId] = metadata;
    setConnectionMap({ ...connectionMap });
  };

  const saveConnections = async () => {
    interface NewConnection {
      placementId: number;
      provider: string;
      agencyId: number;
      metadata: string;
    }

    try {
      const casted = validationSchema.cast(connectionMap, {
        stripUnknown: true,
      });

      await validationSchema.validate(casted, {
        strict: true,
        abortEarly: false,
      });

      const connections: NewConnection[] = [];

      Object.entries(connectionMap).forEach(([agencyId, bidderMap]) => {
        Object.entries(bidderMap).forEach(([provider, { overallData, metadata: placementMap }]) => {
          // don't save non-selected bidders
          if (!selectedBidders.find((b) => b.provider === provider)) {
            return;
          }
          Object.entries(placementMap).forEach(([placementId, metadata]) => {
            connections.push({
              placementId: parseInt(placementId, 10),
              provider,
              agencyId: parseInt(agencyId, 10),
              metadata: JSON.stringify({
                ...overallData,
                ...metadata,
              }),
            });
          });
        });
      });
      await createBulkConnections({
        variables: {
          data: connections,
        },
      });
      setAlertOpen(true);
      setAlertSeverity('success');
      setAlertText('Connections were saved successfully.');
    } catch (e) {
      let message = 'There was an error saving connections.';
      const cErrors: ConnectionMapErrors = {};
      if (e instanceof yup.ValidationError || e instanceof TypeError) {
        message += ' The fields with erros have been marked.';
        let paths: string[] = [];
        let reg = /(.+?) /;
        if (e instanceof yup.ValidationError) {
          paths = e.errors;
        } else {
          paths = [e.message];
          reg = /The value of (.+?) /;
        }
        paths.forEach((error) => {
          const path = error.match(reg)?.[1];
          if (!path) {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let current: any = cErrors;
          path.split('.').forEach((part, i, arr) => {
            if (i === arr.length - 1) {
              current[part] = true;
            } else {
              current[part] = {};
              current = current[part];
            }
          });
        });
      }
      setConnectionMapErrors(cErrors);
      setAlertOpen(true);
      setAlertSeverity('error');
      setAlertText(message);
    }
  };

  return (
    <>
      <h1>{siteName}</h1>
      <BidderSelector
        bidders={bidders}
        selectedBidders={selectedBidders}
        updateSelectedBidders={handleSelectBidderChange}
      />
      {Alert}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Placement</TableCell>
              {selectedBidders.map((selectedBidder) => {
                const bidder = bidders.find(
                  (b) =>
                    b?.agencyId === selectedBidder.agencyId &&
                    b?.provider === selectedBidder.provider,
                );
                if (!bidder) {
                  return null;
                }
                return (
                  <TableCell key={`${bidder.agencyId}${bidder.provider}`}>
                    {bidder.provider} - {bidder.agencyName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell />
              {selectedBidders.map((bidder) => (
                <TableCell key={`${bidder.agencyId}${bidder.provider}`}>
                  <BidderInputs
                    cErrors={connectionMapErrors[bidder.agencyId]?.[bidder.provider]?.overallData}
                    bidder={bidder}
                    metadata={connectionMap[bidder.agencyId][bidder.provider].overallData}
                    updateConnectionMetadata={(field, value) => {
                      updateOverallData(field, value, bidder);
                    }}
                    overall
                  />
                </TableCell>
              ))}
            </TableRow>
            {placements.map((placement) => (
              <PlacementRow
                connectionMapErrors={connectionMapErrors}
                key={placement.id}
                bidders={selectedBidders}
                placement={placement}
                connectionMap={connectionMap}
                updateConnection={updateConnection}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <LoadingButton
        onClick={saveConnections}
        loading={createBulkConnectionsLoading}
        text="Save Connections"
        color="primary"
      />
    </>
  );
};
