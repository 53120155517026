import { Maybe } from '@snapshot/data-access';
import { DataRow } from '../../types/datatables';
import { AllKeys } from '../../types/helpers';
// eslint-disable-next-line import/extensions
import type { CellValue, ColumnDefinitionContext } from './column-definitions';

export const numberFormatter = <K extends AllKeys<DataRow>>(precision = 0) => (
  context: ColumnDefinitionContext<K> & {
    value?: Maybe<number | string>;
  },
): CellValue => {
  const { value } = context;
  if (Number.isNaN(value?.toString())) {
    return value;
  }
  const valueAsNumber = Number(value);
  if (precision) {
    return (valueAsNumber || 0).toFixed(precision);
  }
  return value?.toLocaleString();
};

export const currencyFormatter = <K extends AllKeys<DataRow>>(precision = 0) => (
  context: ColumnDefinitionContext<K> & {
    value?: Maybe<number | string>;
  },
): CellValue => {
  const { value } = context;
  if (Number.isNaN(value?.toString())) {
    return value;
  }

  const valueAsNumber = Number(value);

  const number = `${(valueAsNumber || 0).toFixed(precision)}`;

  const [a, b] = number.split('.');

  return `$${Number(a).toLocaleString()}.${b}`;
};
