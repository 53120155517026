import * as yup from 'yup';
import { Bidder } from '@snapshot/prisma-client';
import { adaptmx } from './adaptmx';
import { appnexus } from './appnexus';
import { conversant } from './conversant';
import { districtm } from './districtm';
import { districtmdmx } from './districtmdmx';
import { google } from './google';
import { gumgum } from './gumgum';
import { ix } from './ix';
import { medianet } from './medianet';
import { nobid } from './nobid';
import { oftmedia } from './oftmedia';
import { onetag } from './onetag';
import { openx } from './openx';
import { pubmatic } from './pubmatic';
import { sharethrough } from './sharethrough';
import { sonobi } from './sonobi';
import { sovrn } from './sovrn';
import { triplelift } from './triplelift';
import { unruly } from './unruly';
import { verizonmedia } from './verizonmedia';

interface BidderRequirements {
  connection: ReturnType<typeof yup.object>;
  reporting: ReturnType<typeof yup.object>;
}

export interface BidderAdapter {
  name: string;
  requirements: BidderRequirements;
  getConnectionValues?: (bidder: Pick<Bidder, 'metadata'>) => Record<string, unknown>;
  overallRequirements: string[];
}

export const adapters = [
  adaptmx,
  appnexus,
  conversant,
  districtm,
  districtmdmx,
  google,
  gumgum,
  ix,
  medianet,
  nobid,
  oftmedia,
  onetag,
  openx,
  pubmatic,
  sharethrough,
  sonobi,
  sovrn,
  triplelift,
  unruly,
  verizonmedia,
];
