import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    placementId: yup.string().required(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
  }),
};

export const unruly: BidderAdapter = {
  name: 'unruly',
  requirements,
  overallRequirements: [],
};
