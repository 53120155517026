import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useField } from 'formik';
import React from 'react';
import { Size, sizes } from './sizes';

interface ExtraFormFields {
  name: string;
  label: string;
  hasError?: boolean;
  errorMessage?: string;
  fluidAvailable?: boolean;
}

type FormDimensionsFieldProps = Omit<
  Partial<SelectProps>,
  'id' | 'value' | 'onChange' | 'error' | 'helperText'
> &
  ExtraFormFields;

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  select: {
    minHeight: 40,
  },
}));

export const FormSizesField: React.FC<FormDimensionsFieldProps> = ({
  name,
  hasError,
  errorMessage,
  fluidAvailable = false,
  label,
  ...rest
}) => {
  const [field, meta, { setValue }] = useField<Size[1][]>(name);

  const classes = useStyles();

  return (
    <FormControl className="mb-3">
      <InputLabel>{label}</InputLabel>
      <Select
        {...rest}
        id={`${name}`}
        name={`${name}`}
        value={field.value}
        onChange={field.onChange}
        error={hasError || (meta.touched && Boolean(meta.error))}
        onBlur={field.onBlur}
        className={clsx('mr-2', classes.select)}
        multiple
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as Size[1][]).map((val) => (
              <Chip
                color="secondary"
                variant="outlined"
                size="small"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                key={val as any}
                label={typeof val === 'string' ? val : val.join('x')}
                className={classes.chip}
                onDelete={() => {
                  setValue((field.value as Size[1][]).filter((v) => v !== val));
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
            ))}
          </div>
        )}
      >
        {sizes.map(([sizeLabel, val], i) => (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <MenuItem key={sizeLabel as any} dense value={val as any}>
            <Checkbox
              size="small"
              checked={field.value.findIndex((s) => s[0] === val[0] && s[1] === val[1]) > -1}
            />
            <ListItemText primary={sizeLabel} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{(meta.touched && meta.error) || errorMessage}</FormHelperText>
    </FormControl>
  );
};
