import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    placement_id: yup.string().required(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
    site_id: yup.string(),
    publisher_id: yup.string(),
  }),
};

export const appnexus: BidderAdapter = {
  name: 'appnexus',
  requirements,
  overallRequirements: [],
};
