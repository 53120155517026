import React, { PropsWithChildren } from 'react';
import { Formik, FormikConfig, Form } from 'formik';
import { Box, Button, CircularProgress, InputProps } from '@material-ui/core';
import * as yup from 'yup';
import { TypedSchema } from 'yup/lib/util/types';
import { objectTyped } from '@snapshot/shared-utils';
import { LoadingButton } from '../../loading-button/loading-button';
import { FormTextField } from '../form-text-field/form-text-field';
import { FormSelectField } from '../form-select-field/form-select-field';

type StringKeys<T> = Extract<keyof T, string>;

const fieldTypes = ['text', 'password', 'email', 'select', 'number'] as const;

type FormField = typeof FormTextField | typeof FormSelectField;

const fieldTypeMapping: Record<typeof fieldTypes[number], FormField> = {
  text: FormTextField,
  password: FormTextField,
  email: FormTextField,
  select: FormSelectField,
  number: FormTextField,
};

export interface FieldMapping<T extends unknown> {
  validator: yup.BaseSchema & TypedSchema;
  type: typeof fieldTypes[number];
  label: string;
  inputProps?: InputProps;
  initialValue: T;
  hasError?: boolean;
  errorMessage?: string;
  order?: number;
  items?: Array<{ value: string | number; label: string }>;
}

export type FieldMap<T extends Record<string, unknown>> = {
  [key in StringKeys<T>]: FieldMapping<T[key]>;
};

interface FormContainerProps<Values extends Record<string, unknown>> {
  fields: FieldMap<Values>;
  onSubmit: FormikConfig<Values>['onSubmit'];
  submitButtonText?: string;
  loading?: boolean;
}
export const FormContainer = <Values extends Record<string, unknown>>({
  fields,
  onSubmit,
  submitButtonText = 'Submit',
  loading = false,
  children,
}: PropsWithChildren<FormContainerProps<Values>>): React.ReactElement | null => {
  const innerValidationSchema: Record<StringKeys<Values>, yup.BaseSchema> = {} as Record<
    StringKeys<Values>,
    yup.BaseSchema
  >;

  const initialValues: FormikConfig<Values>['initialValues'] = {} as FormikConfig<Values>['initialValues'];

  objectTyped.keys(fields).forEach((key) => {
    const field = fields[key];
    innerValidationSchema[key] = field.validator.label(field.label);
    initialValues[key] = field.initialValue as Values[Extract<keyof Values, string>];
  });

  const validationSchema = yup.object().shape(innerValidationSchema);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form className="px-5">
        {objectTyped.keys(fields).map((key) => {
          const field = fields[key];
          const Component = fieldTypeMapping[field.type];
          return (
            <Component
              key={key}
              type={field.type}
              name={key}
              label={field.label}
              InputProps={field.inputProps}
              hasError={field.hasError}
              errorMessage={field.errorMessage}
              options={field.items || []}
            />
          );
        })}
        <Box className="text-center">
          <LoadingButton color="primary" size="large" loading={loading} text={submitButtonText} />
        </Box>
        {children}
      </Form>
    </Formik>
  );
};
