import React, { useEffect, useState } from 'react';
import { Grid, Box, Backdrop, CircularProgress, makeStyles, createStyles } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';
import { Maybe } from '@snapshot/data-access';
import { notEmpty } from '@snapshot/shared-utils';
import { DateRangePicker } from './date-range-picker';
import { StoredUser } from '../store/authentication/auth.slice';
import { DataTableContainer } from './data-table/data-table-container';
import { DataExport } from './data-export/data-export';
import { DataTableContext } from '../types/datatables';
import { PublisherSelect } from './api-select/publisher-select';

interface StatsTableProps {
  title: string;
  rows?: Maybe<Maybe<Record<string, unknown>>[]>;
  totals?: Maybe<Maybe<Record<string, unknown>>[]>;
  loading: boolean;
  fetchStats: (
    dateConfig: { startDate: Moment; endDate: Moment },
    publisherId?: number | null,
  ) => void;
  user?: StoredUser;
  startDate?: Moment;
  endDate?: Moment;
  publisherFilter?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      backgroundColor: 'rgba(255,255,255,0.5)',
      zIndex: theme.zIndex.drawer + 1,
      color: '#AAA',
      position: 'absolute',
    },
  }),
);

export const StatsTable = ({
  title,
  rows,
  totals,
  loading,
  user,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  fetchStats,
  publisherFilter,
}: StatsTableProps) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(
    defaultStartDate || moment.tz(undefined, 'UTC').subtract(1, 'day').startOf('month'),
  );
  const [endDate, setEndDate] = useState(
    defaultEndDate || moment.tz(undefined, 'UTC').endOf('day'),
  );

  const [publisherId, setPublisherId] = useState<number | null>(null);

  useEffect(() => {
    fetchStats(
      {
        startDate,
        endDate,
      },
      publisherId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherId]);

  const handleSubmitDate = (start?: Moment, end?: Moment) => {
    fetchStats(
      {
        startDate: start || startDate,
        endDate: end || endDate,
      },
      publisherId,
    );
  };

  const context: DataTableContext = {
    scene: 'stats',
    resolution: 'day',
    user,
  };
  const filteredRows = rows?.filter(notEmpty) || [];
  const filteredTotals = totals?.filter(notEmpty) || [];

  const showTable = !!filteredRows?.length;

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12}>
        <Box display="flex" flexWrap="wrap" alignItems="end">
          <Box flexGrow={1}>
            <h1>{title}</h1>
          </Box>
          <Box className="my-auto mr-sm-5">
            <DataExport data={filteredRows} context={context} color="primary" />
          </Box>
          <Box className="ml-md-initial ml-auto">
            <DateRangePicker
              onClickGo={handleSubmitDate}
              onSelectOption={handleSubmitDate}
              startDate={{
                value: startDate,
                setValue: setStartDate,
              }}
              endDate={{
                value: endDate,
                setValue: setEndDate,
              }}
              color="primary"
            />
          </Box>
        </Box>

        <Box maxWidth={650} marginLeft="auto">
          {publisherFilter ? (
            <PublisherSelect value={publisherId} setValue={setPublisherId} />
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {showTable ? (
          <DataTableContainer data={filteredRows} context={context} totals={filteredTotals} />
        ) : null}

        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <Grid className="text-right" item xs={12}>
        <small>* As reported by bidders, subject to change</small>
      </Grid>
    </Grid>
  );
};
