import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { usePublishersQuery } from '@snapshot/data-access';
import { TextField } from '@material-ui/core';

interface PublisherSelectProps {
  value: number | null;
  setValue: (value: number | null) => void;
}

export const PublisherSelect = ({ value, setValue }: PublisherSelectProps) => {
  const { data, loading } = usePublishersQuery();

  return (
    <Autocomplete
      options={data?.publishers || []}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(v) => v.id === value}
      renderInput={(params) => <TextField {...params} label="Publisher" variant="outlined" />}
      onChange={(event, newValue) => {
        setValue(newValue?.id || null);
      }}
      size="small"
    />
  );
};
