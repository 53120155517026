import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    siteId: yup.string().required(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    apiKey: yup.string().required(),
    userId: yup.number().required(),
  }),
};

export const ix: BidderAdapter = {
  name: 'ix',
  requirements,
  overallRequirements: ['siteId'],
};
