import moment from 'moment-timezone';

export const datePickerOptions = [
  {
    label: 'Custom',
    start: null,
    end: null,
  },
  {
    label: 'Last 7 Days',
    start: moment.tz(undefined, 'UTC').subtract(6, 'day').startOf('day'),
    end: moment.tz(undefined, 'UTC').endOf('day'),
  },
  {
    label: 'This Month',
    start: moment.tz(undefined, 'UTC').startOf('month'),
    end: moment.tz(undefined, 'UTC').endOf('day'),
  },
  {
    label: 'Last Month',
    start: moment.tz(undefined, 'UTC').subtract(1, 'month').startOf('month'),
    end: moment.tz(undefined, 'UTC').subtract(1, 'month').endOf('month'),
  },
  {
    label: 'This Year',
    start: moment.tz(undefined, 'UTC').startOf('year'),
    end: moment.tz(undefined, 'UTC').endOf('day'),
  },
];
