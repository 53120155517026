import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { NavBar, NavBarProps, NavDrawer, NavLinkItem } from '../../navigation';

/* eslint-disable-next-line */
export interface PersistentDrawerLayoutProps {
  drawerWidth?: number;
  drawerNavLinkitems?: NavLinkItem[];
  logo?: NavBarProps['logo'];
  title: string;
  footer: React.ReactElement;
  navBarItems?: React.ReactElement[];
}

interface PersistentDrawerLayoutStyleProps {
  drawerWidth: number;
  aboveLg: boolean;
}

const useStyles = makeStyles<Theme, PersistentDrawerLayoutStyleProps>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: ({ drawerWidth, aboveLg }) => (aboveLg ? -drawerWidth : 0),
      width: '100%',
      backgroundColor: 'white',
    },
    innerContent: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    contentShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: () => 0,
      width: ({ drawerWidth, aboveLg }) => (aboveLg ? `calc(100% - ${drawerWidth}px)` : '100%'),
    },
  }),
);

export function PersistentDrawerLayout({
  children,
  drawerWidth = 240,
  drawerNavLinkitems = [],
  navBarItems,
  logo,
  title,
  footer,
}: React.PropsWithChildren<PersistentDrawerLayoutProps>) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const aboveLg = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const classes = useStyles({ drawerWidth, aboveLg });
  return (
    <div className={classes.root} key="persistent-layout">
      <NavBar
        title={title}
        logo={logo}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        items={navBarItems}
      />
      <div className={classes.innerContent}>
        <NavDrawer
          navItems={drawerNavLinkitems}
          open={drawerOpen}
          setOpen={setDrawerOpen}
          drawerWidth={drawerWidth}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          <Toolbar />
          {children}
        </main>
      </div>
      {footer}
    </div>
  );
}
