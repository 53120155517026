import React, { useMemo } from 'react';
import { useDailyStatsLazyQuery } from '@snapshot/data-access';
import { Moment } from 'moment-timezone';
import { useAppSelector } from '../../store';
import { StatsTable } from '../stats-table';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StatsTableProps {}

export const DailyStatsTable: React.FunctionComponent<StatsTableProps> = () => {
  const user = useAppSelector((state) => state.auth.user);

  const [getStats, { loading, data }] = useDailyStatsLazyQuery();

  const title = 'Daily Stats';

  const fetchStats = useMemo(
    () => ({ startDate, endDate }: { startDate: Moment; endDate: Moment }): void => {
      const variables = {
        startDate: startDate.clone().startOf('day').format(DATE_FORMAT),
        endDate: endDate.clone().endOf('day').format(DATE_FORMAT),
      };
      getStats?.({
        variables,
      });
    },
    [getStats],
  );

  return (
    <StatsTable
      title={title}
      rows={data?.dailyStats}
      totals={data?.dailyStatsTotals}
      loading={loading}
      user={user}
      fetchStats={fetchStats}
    />
  );
};
