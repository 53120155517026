import React from 'react';
import { TextField } from '@material-ui/core';
import { Bidder } from '@snapshot/prisma-client';
import { getBidderAdapter } from '../../../utils/helpers/get-bidder-adapter';
import type { CBidder } from '../connections-page';

interface BidderInputsProps {
  bidder: CBidder;
  metadata: Record<string, unknown>;
  updateConnectionMetadata: (field: string, value: unknown) => void;
  overall?: boolean;
  cErrors?: Record<string, boolean>;
}

export const BidderInputs: React.FunctionComponent<BidderInputsProps> = ({
  metadata,
  bidder,
  updateConnectionMetadata,
  overall = false,
  cErrors = {},
}) => {
  const adapter = getBidderAdapter(bidder.provider);
  const overallFields = adapter?.overallRequirements || [];

  if (!adapter) {
    return null;
  }

  const fields = Object.keys(adapter?.requirements.connection.fields || {})
    .filter((field) => overall === overallFields.includes(field))
    .map((field) => {
      const yupValidator = adapter?.requirements.connection.fields[field];
      let inputType = 'text';

      if (yupValidator.describe().type === 'number') {
        inputType = 'number';
      }
      let error = false;
      if (cErrors[field]) {
        error = true;
      }
      return (
        <div key={`${field}${bidder.agencyId}${bidder.provider}`}>
          <TextField
            error={error}
            label={field}
            margin="dense"
            onChange={(event) => {
              updateConnectionMetadata(field, event.target.value);
            }}
            value={metadata[field] || ''}
            type={inputType}
          />
        </div>
      );
    });

  return <>{fields}</>;
};
