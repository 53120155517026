import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    site_id: yup.string().required(),
    placement_id: yup.string().required(),
    site_id_mobile: yup.string(),
    placement_id_mobile: yup.string(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
  }),
};

export const verizonmedia: BidderAdapter = {
  name: 'verizonmedia',
  requirements,
  overallRequirements: ['site_id', 'site_id_mobile'],
};
