import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@material-ui/core';
import type { RootState } from '../root-reducer';
import { DataRow } from '../../types/datatables';
import { AllKeys } from '../../types/helpers';

// all of this should be moved to react context - only pertinent to the data table components

export interface DataTableState {
  sort: {
    field: AllKeys<DataRow>;
    direction: SortDirection;
  };
  pagination: {
    currentPage: number;
    perPage: number;
  };
}

export const initialState: DataTableState = {
  sort: {
    field: 'id',
    direction: 'asc',
  },
  pagination: {
    currentPage: 0,
    perPage: 50,
  },
};

export const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<DataTableState['pagination']['currentPage']>) => {
      // eslint-disable-next-line no-param-reassign
      state.pagination.currentPage = action.payload;
    },
    setPerPage: (state, action: PayloadAction<DataTableState['pagination']['perPage']>) => {
      // eslint-disable-next-line no-param-reassign
      state.pagination.perPage = action.payload;
    },
    setSort: (
      state,
      action: PayloadAction<{
        field: DataTableState['sort']['field'];
        direction?: DataTableState['sort']['direction'];
      }>,
    ) => {
      if (action.payload.direction) {
        // eslint-disable-next-line no-param-reassign
        state.sort.field = action.payload.field;
        // eslint-disable-next-line no-param-reassign
        state.sort.direction = action.payload.direction;
      } else if (state.sort.field === action.payload.field) {
        if (state.sort.direction === 'asc') {
          // eslint-disable-next-line no-param-reassign
          state.sort.direction = 'desc';
        } else {
          // eslint-disable-next-line no-param-reassign
          state.sort.direction = 'asc';
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        state.sort = {
          field: action.payload.field,
          direction: 'desc',
        };
      }
    },
  },
});

export const { setSort, setPage, setPerPage } = dataTableSlice.actions;

const getSortState = (state: RootState) => state.dataTable.sort;

const getPaginationState = (state: RootState) => state.dataTable.pagination;

export const getSort = createSelector([getSortState], (s) => s);
export const getPagination = createSelector([getPaginationState], (s) => s);

export const { reducer } = dataTableSlice;
