import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
  SwitchProps,
} from '@material-ui/core';
import React from 'react';
import { Field, useField } from 'formik';

interface ExtraFormFields {
  name: string;
  label: string;
  hasError?: boolean;
  errorMessage?: string;
}

type FormCheckboxFieldProps = Omit<
  Partial<SwitchProps>,
  'id' | 'value' | 'onChange' | 'error' | 'helperText'
> &
  ExtraFormFields;

export const FormCheckboxField: React.FunctionComponent<FormCheckboxFieldProps> = ({
  name,
  hasError,
  errorMessage,
  ...rest
}) => {
  const [field, meta] = useField({ name, type: 'boolean' });
  return (
    <FormControl className="w-100 mb-3">
      <FormLabel>{rest.label}</FormLabel>
      <Switch
        {...rest}
        value={field.value}
        onChange={field.onChange}
        id={name}
        onBlur={field.onBlur}
      />
      <FormHelperText>{(meta.touched && meta.error) || errorMessage}</FormHelperText>
    </FormControl>
  );
};
