import React from 'react';
import { TableBody } from '@material-ui/core';
import { DataRow, DataTableContext } from '../../types/datatables';
import { AllKeys, Idx } from '../../types/helpers';
import { DataTableRow } from './data-table-row';

interface DataTableBodyProps {
  data: DataRow[];
  context: DataTableContext;
  totals?: DataRow[];
  actions?: boolean;
}

export const DataTableBody: React.FunctionComponent<DataTableBodyProps> = ({
  data,
  context,
  totals,
  actions = false,
}) => {
  const stats = [...data];
  return (
    <TableBody>
      {stats.map((row) => {
        let idValue: Idx<DataRow, AllKeys<DataRow>> | null = null;
        if ('datetime' in row) {
          idValue = row.datetime;
        } else if ('id' in row) {
          idValue = row.id;
        } else if ('name' in row) {
          idValue = row.name;
        } else if ('site' in row) {
          idValue = row.site?.id;
        } else if ('feedProvider' in row) {
          idValue = row.feedProvider;
        }
        if (!idValue) {
          throw new Error('An id, name or date must be supplied for data tables.');
        }
        return <DataTableRow actions={actions} key={idValue} row={row} context={context} />;
      })}
      {totals
        ? totals.map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <DataTableRow
              key={`totals-${Object.values(row)[0]}`}
              row={row}
              context={context}
              summary
              actions={actions}
            />
          ))
        : null}
    </TableBody>
  );
};
