/**
 * Should accept the following:
 * placement ids
 * feed providers/agencies
 *
 * Should output the following:
 * yup validation schema
 * overalldata separated
 *
 * overall schema example:
 *
 * {
 *  "agency schema"
 *  [agencyId]: {
 *    "bidder schema"
 *    [feedProvider]: {
 *      overallData: { schema with overall fields }
 *      "placement schema"
 *      [placementId]: { schema with metadata fields }
 *    }
 *  }
 * }
 */

import * as yup from 'yup';
import { adapters } from '@snapshot/bidder-configs';
import { getBidderAdapter } from '../../../utils/helpers/get-bidder-adapter';

interface BidderData {
  agencyId: number;
  provider: string;
}

// you can merge yup schemas
// https://stackoverflow.com/questions/68128636/react-yup-formik-how-to-extend-current-schema-from-another-schema

// Builds the dynamic validator tree structure for the connections page as defined above
export const getDynamicValidator = (placementIds: number[], bidders: BidderData[]) => {
  let validationSchema = yup.object().shape({});

  bidders.forEach(({ agencyId }) => {
    let agencySchema = yup.object().shape({});
    bidders.forEach((bidder) => {
      if (agencyId === bidder.agencyId) {
        let bidderSchema = yup.object().shape({});
        let overallSchema = yup.object().shape({});
        let metadataSchema = yup.object().shape({});
        const adapter = getBidderAdapter(bidder.provider);
        if (!adapter) {
          return;
        }
        const { overallRequirements } = adapter;
        Object.entries(adapter.requirements.connection.fields).forEach(([field, schema]) => {
          if (overallRequirements.includes(field)) {
            overallSchema = overallSchema.shape({
              [field]: schema,
            });
          } else {
            metadataSchema = metadataSchema.shape({
              [field]: schema,
            });
          }
        });

        let placementMetadataSchema = yup.object().shape({});

        placementIds.forEach((placementId) => {
          placementMetadataSchema = placementMetadataSchema.shape({
            [placementId]: metadataSchema,
          });
        });

        bidderSchema = bidderSchema.shape({
          overallData: overallSchema,
          metadata: placementMetadataSchema,
        });
        agencySchema = agencySchema.shape({
          [bidder.provider]: bidderSchema,
        });
      }
    });
    validationSchema = validationSchema.shape({
      [agencyId]: agencySchema,
    });
  });

  return validationSchema;
};
