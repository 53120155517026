import React from 'react';
import { ByEntityStatsTable } from '../components/stats-tables/by-entity-stats-table';
import { DailyStatsTable } from '../components/stats-tables/daily-stats-table';
import { EntityDailyStatsTable } from '../components/stats-tables/entity-daily-stats-table';
import { ByProviderStats } from '../components/stats-tables/by-provider-stats';

interface StatsPageProps {
  moduleName?: 'site';
  resolution?: 'day';
  rawId?: string;
  day?: string | string[] | null;
  byProvider?: boolean;
}

export const StatsPage: React.FunctionComponent<StatsPageProps> = ({
  moduleName,
  resolution,
  rawId,
  day,
  byProvider,
}) => {
  const parsedDay = Array.isArray(day) ? day[0] : day || undefined;

  if (byProvider) {
    return <ByProviderStats />;
  }

  if (moduleName) {
    if (rawId) {
      return <EntityDailyStatsTable rawId={rawId} moduleName={moduleName} />;
    }
    return <ByEntityStatsTable moduleName={moduleName} day={parsedDay} />;
  }

  return <DailyStatsTable />;
};
