import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Link } from '@material-ui/core';
import { FieldMap, FormContainer, emailField, passwordField } from '../components/forms';
import { authLogin } from '../store/authentication';
import { useAppDispatch } from '../store';
import { RootState } from '../store/root-reducer';
import { AuthLayout } from '../components/layout/auth';

interface LoginPageProps {
  path?: string;
}

type LoginPageForm = {
  email: string;
  password: string;
};

export const LoginPage: React.FunctionComponent<LoginPageProps> = ({ path }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);

  const fields: FieldMap<LoginPageForm> = {
    email: emailField(true),
    password: passwordField(),
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onSubmit = async (values: LoginPageForm) => {
    const redirect = path || '/';
    try {
      await dispatch(authLogin(values)).then(unwrapResult);
      history.push(redirect);
    } catch {
      setOpen(true);
    }
  };

  return (
    <>
      <AuthLayout>
        <div className="text-center text-black-50 mb-3">
          <span>Sign in with your Datablocks credentials</span>
        </div>
        <FormContainer
          submitButtonText="Sign in"
          loading={auth.loading}
          onSubmit={onSubmit}
          fields={fields}
        >
          <Box>
            Forgot your password? <Link href="/forgot-password">Click here</Link> to request a
            password reset email.
          </Box>
          <Alert hidden={!open} severity="error" onClose={handleClose}>
            Incorrect password
          </Alert>
        </FormContainer>
      </AuthLayout>
    </>
  );
};
