/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.grey[200],
      color: '#666',
      width: '100%',
      fontSize: '0.75em',
      textAlign: 'center',
    },
  }),
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FooterProps {}

export const Footer: React.FunctionComponent<FooterProps> = () => {
  const classes = useStyles();
  return (
    <footer className={clsx(classes.footer)}>
      <Grid container>
        <Grid item xs={12}>
          © Copyright Datablocks Inc. 2021. All rights reserved.
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          Acceptable Use: The use of this platform is subject to minimum terms of acceptable use.
          The full terms of acceptable use may be reviewed by following{' '}
          <a href="https://datablocks.net/terms-of-acceptable-use/" target="_blank">
            this hyperlink
          </a>
          . Users who violate any of the terms of acceptable use may have their access to this
          platform suspended or terminated permanently.
        </Grid>
      </Grid>
    </footer>
  );
};
