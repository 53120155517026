import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** Decimal type */
  Decimal: string;
};

export type Agency = {
  __typename?: 'Agency';
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

export type AgencyWhereInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<StringFilter>;
};

export type AgencyWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Bidder = {
  __typename?: 'Bidder';
  agency?: Maybe<Agency>;
  feedProvider: Scalars['String'];
  id: Scalars['Int'];
  metadata: Scalars['String'];
  name: Scalars['String'];
};

export type BidderRevenue = {
  __typename?: 'BidderRevenue';
  date: Scalars['String'];
  provider: Scalars['String'];
  publisherId: Scalars['Int'];
  revenue: Scalars['Decimal'];
};

export type BidderRevenueInput = {
  agencyId: Scalars['Int'];
  date: Scalars['String'];
  provider: Scalars['String'];
  publisherId: Scalars['Int'];
  revenue: Scalars['Decimal'];
};

export type BidderWhereInput = {
  active?: Maybe<BoolFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
};

export type BidderWhereUniqueInput = {
  id: Scalars['Int'];
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<Scalars['Boolean']>;
};

export type ByProviderStat = {
  __typename?: 'ByProviderStat';
  cliValidatedEarning: Scalars['Decimal'];
  dbValidatedEarning: Scalars['Decimal'];
  feedProvider: Scalars['String'];
  publisher: Publisher;
  publisherId: Scalars['Int'];
  totalValidatedEarning: Scalars['Decimal'];
};

export type ByProviderWhereInput = {
  endDate: Scalars['DateTime'];
  feedProvider?: Maybe<Scalars['String']>;
  publisherId?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  totals?: Maybe<Scalars['Boolean']>;
};

export type BySiteRow = {
  __typename?: 'BySiteRow';
  cliEstimatedCpm?: Maybe<Scalars['Decimal']>;
  cliEstimatedEarning?: Maybe<Scalars['Decimal']>;
  cliImpressions?: Maybe<Scalars['Int']>;
  cliValidatedEarning?: Maybe<Scalars['Decimal']>;
  dbEstimatedCpm?: Maybe<Scalars['Decimal']>;
  dbEstimatedEarning?: Maybe<Scalars['Decimal']>;
  dbImpressions?: Maybe<Scalars['Int']>;
  dbValidatedEarning?: Maybe<Scalars['Decimal']>;
  googleEstimatedCpm?: Maybe<Scalars['Decimal']>;
  googleEstimatedEarning?: Maybe<Scalars['Decimal']>;
  googleImpressions?: Maybe<Scalars['Int']>;
  googleValidatedEarning?: Maybe<Scalars['Decimal']>;
  site: Site;
  siteId: Scalars['Int'];
  totalEstimatedCpm?: Maybe<Scalars['Decimal']>;
  totalEstimatedEarning?: Maybe<Scalars['Decimal']>;
  totalImpressions?: Maybe<Scalars['Int']>;
  totalValidatedEarning?: Maybe<Scalars['Decimal']>;
};

export type Connection = {
  __typename?: 'Connection';
  agency?: Maybe<Agency>;
  bidder?: Maybe<Bidder>;
  bidderUID?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['String']>;
  placement?: Maybe<Placement>;
  sourceUID?: Maybe<Scalars['Int']>;
};

export type ConnectionBidder = {
  __typename?: 'ConnectionBidder';
  agencyId: Scalars['Int'];
  agencyName: Scalars['String'];
  metadata: Scalars['String'];
  provider: Scalars['String'];
};

export type ConnectionBidderWhereInput = {
  publisherId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
};

export type ConnectionWhereInput = {
  agencyUID?: Maybe<IntFilter>;
  bidderUID?: Maybe<IntFilter>;
  sourceUID?: Maybe<IntFilter>;
};

export type ConnectionsPageWhereInput = {
  agencyId: Scalars['Int'];
  feedProvider: Scalars['String'];
  siteId: Scalars['Int'];
};

export type CreateBulkConnectionsInput = {
  agencyId: Scalars['Int'];
  metadata: Scalars['String'];
  placementId: Scalars['Int'];
  provider: Scalars['String'];
};

export type CreatePlacementArgs = {
  code: Scalars['String'];
  details: Scalars['String'];
  divId: Scalars['String'];
  googleCode: Scalars['String'];
  metadata: Scalars['String'];
  networkCode: Scalars['String'];
  siteId: Scalars['Int'];
};

export type CreateSiteArgs = {
  feedtimeout: Scalars['Int'];
  name: Scalars['String'];
  publisherId: Scalars['Int'];
};

export type DailySiteStatRow = {
  __typename?: 'DailySiteStatRow';
  cliEstimatedCpm?: Maybe<Scalars['Decimal']>;
  cliEstimatedEarning?: Maybe<Scalars['Decimal']>;
  cliImpressions?: Maybe<Scalars['Int']>;
  cliValidatedEarning?: Maybe<Scalars['Decimal']>;
  datetime?: Maybe<Scalars['DateTime']>;
  dbEstimatedCpm?: Maybe<Scalars['Decimal']>;
  dbEstimatedEarning?: Maybe<Scalars['Decimal']>;
  dbImpressions?: Maybe<Scalars['Int']>;
  dbValidatedEarning?: Maybe<Scalars['Decimal']>;
  googleEstimatedCpm?: Maybe<Scalars['Decimal']>;
  googleEstimatedEarning?: Maybe<Scalars['Decimal']>;
  googleImpressions?: Maybe<Scalars['Int']>;
  googleValidatedEarning?: Maybe<Scalars['Decimal']>;
  totalEstimatedCpm?: Maybe<Scalars['Decimal']>;
  totalEstimatedEarning?: Maybe<Scalars['Decimal']>;
  totalImpressions?: Maybe<Scalars['Int']>;
  totalValidatedEarning?: Maybe<Scalars['Decimal']>;
};

export type DailyStatRow = {
  __typename?: 'DailyStatRow';
  cliEstimatedCpm?: Maybe<Scalars['Decimal']>;
  cliEstimatedEarning?: Maybe<Scalars['Decimal']>;
  cliImpressions?: Maybe<Scalars['Int']>;
  cliValidatedEarning?: Maybe<Scalars['Decimal']>;
  datetime?: Maybe<Scalars['DateTime']>;
  dbEstimatedCpm?: Maybe<Scalars['Decimal']>;
  dbEstimatedEarning?: Maybe<Scalars['Decimal']>;
  dbImpressions?: Maybe<Scalars['Int']>;
  dbValidatedEarning?: Maybe<Scalars['Decimal']>;
  googleEstimatedCpm?: Maybe<Scalars['Decimal']>;
  googleEstimatedEarning?: Maybe<Scalars['Decimal']>;
  googleImpressions?: Maybe<Scalars['Int']>;
  googleValidatedEarning?: Maybe<Scalars['Decimal']>;
  totalEstimatedCpm?: Maybe<Scalars['Decimal']>;
  totalEstimatedEarning?: Maybe<Scalars['Decimal']>;
  totalImpressions?: Maybe<Scalars['Int']>;
  totalValidatedEarning?: Maybe<Scalars['Decimal']>;
};



export type DecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<Scalars['Decimal']>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Int']>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBulkConnections?: Maybe<Array<Connection>>;
  createPlacement: Placement;
  createSite: Site;
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  resetUserPassword?: Maybe<Scalars['Boolean']>;
  setBidderRevenue?: Maybe<BidderRevenue>;
  updateOneUser?: Maybe<User>;
  updateSite: Site;
  updateUserPassword?: Maybe<User>;
};


export type MutationCreateBulkConnectionsArgs = {
  data?: Maybe<Array<CreateBulkConnectionsInput>>;
};


export type MutationCreatePlacementArgs = {
  data: CreatePlacementArgs;
};


export type MutationCreateSiteArgs = {
  data: CreateSiteArgs;
};


export type MutationRequestPasswordResetArgs = {
  where: RequestPasswordResetInput;
};


export type MutationResetUserPasswordArgs = {
  data: ResetUserPasswordInput;
  where: ResetUserPasswordWhereInput;
};


export type MutationSetBidderRevenueArgs = {
  data: BidderRevenueInput;
};


export type MutationUpdateOneUserArgs = {
  data?: Maybe<UserUpdateInput>;
  where: UserWhereUniqueInput;
};


export type MutationUpdateSiteArgs = {
  data: UpdateSiteArgs;
  where: SiteWhereUniqueInput;
};


export type MutationUpdateUserPasswordArgs = {
  data: UserUpdatePasswordInput;
  where: UserWhereUniqueInput;
};

export type PageConnection = {
  __typename?: 'PageConnection';
  agencyId: Scalars['Int'];
  feedProvider: Scalars['String'];
  metadata: Scalars['String'];
  placementId: Scalars['Int'];
};

export type Permission = {
  __typename?: 'Permission';
  agency?: Maybe<Agency>;
  agencyId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  user: User;
};

export type PermissionWhereInput = {
  user?: Maybe<UserWhereUniqueInput>;
};

export type Placement = {
  __typename?: 'Placement';
  code: Scalars['String'];
  connections?: Maybe<Array<Connection>>;
  divId?: Maybe<Scalars['String']>;
  googleCode?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['String']>;
  networkCode?: Maybe<Scalars['String']>;
  site?: Maybe<Site>;
};

export type PlacementWhereInput = {
  siteId?: Maybe<IntFilter>;
};

export type Publisher = {
  __typename?: 'Publisher';
  agency: Agency;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PublisherWhereInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<StringFilter>;
};

export type Query = {
  __typename?: 'Query';
  agencies?: Maybe<Array<Agency>>;
  bidder?: Maybe<Bidder>;
  bidders?: Maybe<Array<Bidder>>;
  byProviderStats?: Maybe<Array<ByProviderStat>>;
  bySiteStats?: Maybe<Array<BySiteRow>>;
  connectionBidders?: Maybe<Array<ConnectionBidder>>;
  connections?: Maybe<Array<Connection>>;
  connectionsPage?: Maybe<Array<PageConnection>>;
  dailySiteStats?: Maybe<Array<DailySiteStatRow>>;
  dailyStats?: Maybe<Array<DailyStatRow>>;
  placements?: Maybe<Array<Placement>>;
  publishers?: Maybe<Array<Publisher>>;
  site?: Maybe<Site>;
  sites?: Maybe<Array<Site>>;
  userProfile?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  zeroSiteStats?: Maybe<Array<BySiteRow>>;
};


export type QueryAgenciesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AgencyWhereInput>;
};


export type QueryBidderArgs = {
  where: BidderWhereUniqueInput;
};


export type QueryBiddersArgs = {
  where?: Maybe<BidderWhereInput>;
};


export type QueryByProviderStatsArgs = {
  where: ByProviderWhereInput;
};


export type QueryBySiteStatsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  totals?: Maybe<Scalars['Boolean']>;
};


export type QueryConnectionBiddersArgs = {
  where?: Maybe<ConnectionBidderWhereInput>;
};


export type QueryConnectionsArgs = {
  where: ConnectionWhereInput;
};


export type QueryConnectionsPageArgs = {
  where: ConnectionsPageWhereInput;
};


export type QueryDailySiteStatsArgs = {
  endDate: Scalars['DateTime'];
  siteId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  totals?: Maybe<Scalars['Boolean']>;
};


export type QueryDailyStatsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  totals?: Maybe<Scalars['Boolean']>;
};


export type QueryPlacementsArgs = {
  where: PlacementWhereInput;
};


export type QueryPublishersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PublisherWhereInput>;
};


export type QuerySiteArgs = {
  where: SiteWhereUniqueInput;
};


export type QuerySitesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryZeroSiteStatsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  totals?: Maybe<Scalars['Boolean']>;
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type ResetUserPasswordInput = {
  password: Scalars['String'];
};

export type ResetUserPasswordWhereInput = {
  token: Scalars['String'];
};

export type Site = {
  __typename?: 'Site';
  feedtimeout: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  publisher: Publisher;
  publisherId: Scalars['Int'];
};

export type SiteWhereUniqueInput = {
  id: Scalars['Int'];
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type UpdateSiteArgs = {
  feedtimeout?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  publisherId?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  fname?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lname?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
  superadmin: Scalars['Boolean'];
};

export type UserUpdateInput = {
  active: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
};

export type UserUpdatePasswordInput = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};

export type UserWhereInput = {
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type AgenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type AgenciesQuery = (
  { __typename?: 'Query' }
  & { agencies?: Maybe<Array<(
    { __typename?: 'Agency' }
    & Pick<Agency, 'id' | 'name'>
  )>> }
);

export type GetBidderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetBidderQuery = (
  { __typename?: 'Query' }
  & { bidder?: Maybe<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'id' | 'name'>
  )> }
);

export type BiddersQueryVariables = Exact<{ [key: string]: never; }>;


export type BiddersQuery = (
  { __typename?: 'Query' }
  & { bidders?: Maybe<Array<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'id' | 'name' | 'feedProvider' | 'metadata'>
  )>> }
);

export type ConnectionBiddersQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type ConnectionBiddersQuery = (
  { __typename?: 'Query' }
  & { connectionBidders?: Maybe<Array<(
    { __typename?: 'ConnectionBidder' }
    & Pick<ConnectionBidder, 'provider' | 'metadata' | 'agencyId' | 'agencyName'>
  )>> }
);

export type SetBidderRevenueMutationVariables = Exact<{
  provider: Scalars['String'];
  publisherId: Scalars['Int'];
  agencyId: Scalars['Int'];
  revenue: Scalars['Decimal'];
  date: Scalars['String'];
}>;


export type SetBidderRevenueMutation = (
  { __typename?: 'Mutation' }
  & { setBidderRevenue?: Maybe<(
    { __typename?: 'BidderRevenue' }
    & Pick<BidderRevenue, 'provider' | 'revenue' | 'date' | 'publisherId'>
  )> }
);

export type GetConnectionBiddersQueryVariables = Exact<{
  publisherId?: Maybe<Scalars['Int']>;
}>;


export type GetConnectionBiddersQuery = (
  { __typename?: 'Query' }
  & { connectionBidders?: Maybe<Array<(
    { __typename?: 'ConnectionBidder' }
    & Pick<ConnectionBidder, 'provider' | 'metadata' | 'agencyId' | 'agencyName'>
  )>> }
);

export type ByProviderStatsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  publisherId?: Maybe<Scalars['Int']>;
}>;


export type ByProviderStatsQuery = (
  { __typename?: 'Query' }
  & { byProviderStats?: Maybe<Array<(
    { __typename?: 'ByProviderStat' }
    & Pick<ByProviderStat, 'feedProvider' | 'dbValidatedEarning' | 'cliValidatedEarning' | 'totalValidatedEarning'>
  )>>, byProviderStatsTotals?: Maybe<Array<(
    { __typename?: 'ByProviderStat' }
    & Pick<ByProviderStat, 'dbValidatedEarning' | 'cliValidatedEarning' | 'totalValidatedEarning'>
  )>> }
);

export type BySingleProviderStatsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  publisherId?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
}>;


export type BySingleProviderStatsQuery = (
  { __typename?: 'Query' }
  & { byProviderStatsTotals?: Maybe<Array<(
    { __typename?: 'ByProviderStat' }
    & Pick<ByProviderStat, 'dbValidatedEarning' | 'cliValidatedEarning' | 'totalValidatedEarning'>
  )>> }
);

export type BySiteStatsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type BySiteStatsQuery = (
  { __typename?: 'Query' }
  & { bySiteStats?: Maybe<Array<(
    { __typename?: 'BySiteRow' }
    & Pick<BySiteRow, 'googleImpressions' | 'googleEstimatedEarning' | 'googleValidatedEarning' | 'googleEstimatedCpm' | 'totalImpressions' | 'totalEstimatedEarning' | 'totalValidatedEarning' | 'totalEstimatedCpm' | 'dbImpressions' | 'dbEstimatedEarning' | 'dbValidatedEarning' | 'dbEstimatedCpm' | 'cliImpressions' | 'cliEstimatedEarning' | 'cliValidatedEarning' | 'cliEstimatedCpm'>
    & { site: (
      { __typename?: 'Site' }
      & Pick<Site, 'id' | 'name'>
    ) }
  )>>, bySiteStatsTotals?: Maybe<Array<(
    { __typename?: 'BySiteRow' }
    & Pick<BySiteRow, 'googleImpressions' | 'googleEstimatedEarning' | 'googleValidatedEarning' | 'googleEstimatedCpm' | 'totalImpressions' | 'totalEstimatedEarning' | 'totalValidatedEarning' | 'totalEstimatedCpm' | 'dbImpressions' | 'dbEstimatedEarning' | 'dbValidatedEarning' | 'dbEstimatedCpm' | 'cliImpressions' | 'cliEstimatedEarning' | 'cliValidatedEarning' | 'cliEstimatedCpm'>
  )>> }
);

export type ConnectionsQueryVariables = Exact<{
  siteId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ConnectionsQuery = (
  { __typename?: 'Query' }
  & { connections?: Maybe<Array<(
    { __typename?: 'Connection' }
    & Pick<Connection, 'id' | 'metadata'>
    & { placement?: Maybe<(
      { __typename?: 'Placement' }
      & Pick<Placement, 'id'>
    )>, bidder?: Maybe<(
      { __typename?: 'Bidder' }
      & Pick<Bidder, 'id'>
    )> }
  )>> }
);

export type ConnectionsPageQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type ConnectionsPageQuery = (
  { __typename?: 'Query' }
  & { placements?: Maybe<Array<(
    { __typename?: 'Placement' }
    & Pick<Placement, 'id' | 'code' | 'divId' | 'googleCode' | 'networkCode' | 'metadata'>
  )>>, site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )>, connectionBidders?: Maybe<Array<(
    { __typename?: 'ConnectionBidder' }
    & Pick<ConnectionBidder, 'provider' | 'metadata' | 'agencyId' | 'agencyName'>
  )>> }
);

export type PageConnectionsQueryVariables = Exact<{
  siteId: Scalars['Int'];
  feedProvider: Scalars['String'];
  agencyId: Scalars['Int'];
}>;


export type PageConnectionsQuery = (
  { __typename?: 'Query' }
  & { connectionsPage?: Maybe<Array<(
    { __typename?: 'PageConnection' }
    & Pick<PageConnection, 'agencyId' | 'feedProvider' | 'placementId' | 'metadata'>
  )>> }
);

export type CreateBulkConnectionsMutationVariables = Exact<{
  data?: Maybe<Array<CreateBulkConnectionsInput> | CreateBulkConnectionsInput>;
}>;


export type CreateBulkConnectionsMutation = (
  { __typename?: 'Mutation' }
  & { createBulkConnections?: Maybe<Array<(
    { __typename?: 'Connection' }
    & Pick<Connection, 'id'>
  )>> }
);

export type DailySiteStatsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
}>;


export type DailySiteStatsQuery = (
  { __typename?: 'Query' }
  & { dailySiteStats?: Maybe<Array<(
    { __typename?: 'DailySiteStatRow' }
    & Pick<DailySiteStatRow, 'datetime' | 'googleImpressions' | 'googleEstimatedEarning' | 'googleValidatedEarning' | 'googleEstimatedCpm' | 'totalImpressions' | 'totalEstimatedEarning' | 'totalValidatedEarning' | 'totalEstimatedCpm' | 'dbImpressions' | 'dbEstimatedEarning' | 'dbValidatedEarning' | 'dbEstimatedCpm' | 'cliImpressions' | 'cliEstimatedEarning' | 'cliValidatedEarning' | 'cliEstimatedCpm'>
  )>>, dailySiteStatsTotals?: Maybe<Array<(
    { __typename?: 'DailySiteStatRow' }
    & Pick<DailySiteStatRow, 'googleImpressions' | 'googleEstimatedEarning' | 'googleValidatedEarning' | 'googleEstimatedCpm' | 'totalImpressions' | 'totalEstimatedEarning' | 'totalValidatedEarning' | 'totalEstimatedCpm' | 'dbImpressions' | 'dbEstimatedEarning' | 'dbValidatedEarning' | 'dbEstimatedCpm' | 'cliImpressions' | 'cliEstimatedEarning' | 'cliValidatedEarning' | 'cliEstimatedCpm'>
  )>>, site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )> }
);

export type DailyStatsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type DailyStatsQuery = (
  { __typename?: 'Query' }
  & { dailyStats?: Maybe<Array<(
    { __typename?: 'DailyStatRow' }
    & Pick<DailyStatRow, 'datetime' | 'googleImpressions' | 'googleEstimatedEarning' | 'googleValidatedEarning' | 'googleEstimatedCpm' | 'totalImpressions' | 'totalEstimatedEarning' | 'totalValidatedEarning' | 'totalEstimatedCpm' | 'dbImpressions' | 'dbEstimatedEarning' | 'dbValidatedEarning' | 'dbEstimatedCpm' | 'cliImpressions' | 'cliEstimatedEarning' | 'cliValidatedEarning' | 'cliEstimatedCpm'>
  )>>, dailyStatsTotals?: Maybe<Array<(
    { __typename?: 'DailyStatRow' }
    & Pick<DailyStatRow, 'googleImpressions' | 'googleEstimatedEarning' | 'googleValidatedEarning' | 'googleEstimatedCpm' | 'totalImpressions' | 'totalEstimatedEarning' | 'totalValidatedEarning' | 'totalEstimatedCpm' | 'dbImpressions' | 'dbEstimatedEarning' | 'dbValidatedEarning' | 'dbEstimatedCpm' | 'cliImpressions' | 'cliEstimatedEarning' | 'cliValidatedEarning' | 'cliEstimatedCpm'>
  )>> }
);

export type PlacementsQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type PlacementsQuery = (
  { __typename?: 'Query' }
  & { placements?: Maybe<Array<(
    { __typename?: 'Placement' }
    & Pick<Placement, 'id' | 'code' | 'divId' | 'googleCode' | 'networkCode' | 'metadata'>
  )>> }
);

export type CreatePlacementMutationVariables = Exact<{
  siteId: Scalars['Int'];
  code: Scalars['String'];
  divId: Scalars['String'];
  googleCode: Scalars['String'];
  networkCode: Scalars['String'];
  metadata: Scalars['String'];
  details: Scalars['String'];
}>;


export type CreatePlacementMutation = (
  { __typename?: 'Mutation' }
  & { createPlacement: (
    { __typename?: 'Placement' }
    & Pick<Placement, 'id' | 'code' | 'divId' | 'googleCode' | 'networkCode' | 'metadata'>
  ) }
);

export type PublishersQueryVariables = Exact<{ [key: string]: never; }>;


export type PublishersQuery = (
  { __typename?: 'Query' }
  & { publishers?: Maybe<Array<(
    { __typename?: 'Publisher' }
    & Pick<Publisher, 'id' | 'name'>
  )>> }
);

export type GetSiteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'publisherId' | 'feedtimeout'>
  )> }
);

export type SitesQueryVariables = Exact<{ [key: string]: never; }>;


export type SitesQuery = (
  { __typename?: 'Query' }
  & { sites?: Maybe<Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )>> }
);

export type CreateSiteMutationVariables = Exact<{
  name: Scalars['String'];
  feedtimeout: Scalars['Int'];
  publisherId: Scalars['Int'];
}>;


export type CreateSiteMutation = (
  { __typename?: 'Mutation' }
  & { createSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  ) }
);

export type UpdateSiteMutationVariables = Exact<{
  name: Scalars['String'];
  feedtimeout: Scalars['Int'];
  publisherId: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type UpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & { updateSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  ) }
);

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { userProfile?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fname' | 'lname' | 'email' | 'superadmin'>
    & { permissions?: Maybe<Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'agencyId'>
    )>> }
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fname' | 'lname' | 'email' | 'superadmin' | 'active' | 'deleted'>
  )>>> }
);

export type ResetUserPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetUserPassword'>
);

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPasswordReset'>
);


export const AgenciesDocument = gql`
    query Agencies {
  agencies {
    id
    name
  }
}
    `;

/**
 * __useAgenciesQuery__
 *
 * To run a query within a React component, call `useAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgenciesQuery(baseOptions?: Apollo.QueryHookOptions<AgenciesQuery, AgenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgenciesQuery, AgenciesQueryVariables>(AgenciesDocument, options);
      }
export function useAgenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgenciesQuery, AgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgenciesQuery, AgenciesQueryVariables>(AgenciesDocument, options);
        }
export type AgenciesQueryHookResult = ReturnType<typeof useAgenciesQuery>;
export type AgenciesLazyQueryHookResult = ReturnType<typeof useAgenciesLazyQuery>;
export type AgenciesQueryResult = Apollo.QueryResult<AgenciesQuery, AgenciesQueryVariables>;
export const GetBidderDocument = gql`
    query GetBidder($id: Int!) {
  bidder(where: {id: $id}) {
    id
    name
  }
}
    `;

/**
 * __useGetBidderQuery__
 *
 * To run a query within a React component, call `useGetBidderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBidderQuery(baseOptions: Apollo.QueryHookOptions<GetBidderQuery, GetBidderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBidderQuery, GetBidderQueryVariables>(GetBidderDocument, options);
      }
export function useGetBidderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBidderQuery, GetBidderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBidderQuery, GetBidderQueryVariables>(GetBidderDocument, options);
        }
export type GetBidderQueryHookResult = ReturnType<typeof useGetBidderQuery>;
export type GetBidderLazyQueryHookResult = ReturnType<typeof useGetBidderLazyQuery>;
export type GetBidderQueryResult = Apollo.QueryResult<GetBidderQuery, GetBidderQueryVariables>;
export const BiddersDocument = gql`
    query Bidders {
  bidders {
    id
    name
    feedProvider
    metadata
  }
}
    `;

/**
 * __useBiddersQuery__
 *
 * To run a query within a React component, call `useBiddersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiddersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiddersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBiddersQuery(baseOptions?: Apollo.QueryHookOptions<BiddersQuery, BiddersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BiddersQuery, BiddersQueryVariables>(BiddersDocument, options);
      }
export function useBiddersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BiddersQuery, BiddersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BiddersQuery, BiddersQueryVariables>(BiddersDocument, options);
        }
export type BiddersQueryHookResult = ReturnType<typeof useBiddersQuery>;
export type BiddersLazyQueryHookResult = ReturnType<typeof useBiddersLazyQuery>;
export type BiddersQueryResult = Apollo.QueryResult<BiddersQuery, BiddersQueryVariables>;
export const ConnectionBiddersDocument = gql`
    query ConnectionBidders($siteId: Int!) {
  connectionBidders(where: {siteId: $siteId}) {
    provider
    metadata
    agencyId
    agencyName
  }
}
    `;

/**
 * __useConnectionBiddersQuery__
 *
 * To run a query within a React component, call `useConnectionBiddersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionBiddersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionBiddersQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useConnectionBiddersQuery(baseOptions: Apollo.QueryHookOptions<ConnectionBiddersQuery, ConnectionBiddersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionBiddersQuery, ConnectionBiddersQueryVariables>(ConnectionBiddersDocument, options);
      }
export function useConnectionBiddersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionBiddersQuery, ConnectionBiddersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionBiddersQuery, ConnectionBiddersQueryVariables>(ConnectionBiddersDocument, options);
        }
export type ConnectionBiddersQueryHookResult = ReturnType<typeof useConnectionBiddersQuery>;
export type ConnectionBiddersLazyQueryHookResult = ReturnType<typeof useConnectionBiddersLazyQuery>;
export type ConnectionBiddersQueryResult = Apollo.QueryResult<ConnectionBiddersQuery, ConnectionBiddersQueryVariables>;
export const SetBidderRevenueDocument = gql`
    mutation SetBidderRevenue($provider: String!, $publisherId: Int!, $agencyId: Int!, $revenue: Decimal!, $date: String!) {
  setBidderRevenue(
    data: {provider: $provider, publisherId: $publisherId, revenue: $revenue, date: $date, agencyId: $agencyId}
  ) {
    provider
    revenue
    date
    publisherId
  }
}
    `;
export type SetBidderRevenueMutationFn = Apollo.MutationFunction<SetBidderRevenueMutation, SetBidderRevenueMutationVariables>;

/**
 * __useSetBidderRevenueMutation__
 *
 * To run a mutation, you first call `useSetBidderRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBidderRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBidderRevenueMutation, { data, loading, error }] = useSetBidderRevenueMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      publisherId: // value for 'publisherId'
 *      agencyId: // value for 'agencyId'
 *      revenue: // value for 'revenue'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useSetBidderRevenueMutation(baseOptions?: Apollo.MutationHookOptions<SetBidderRevenueMutation, SetBidderRevenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBidderRevenueMutation, SetBidderRevenueMutationVariables>(SetBidderRevenueDocument, options);
      }
export type SetBidderRevenueMutationHookResult = ReturnType<typeof useSetBidderRevenueMutation>;
export type SetBidderRevenueMutationResult = Apollo.MutationResult<SetBidderRevenueMutation>;
export type SetBidderRevenueMutationOptions = Apollo.BaseMutationOptions<SetBidderRevenueMutation, SetBidderRevenueMutationVariables>;
export const GetConnectionBiddersDocument = gql`
    query GetConnectionBidders($publisherId: Int) {
  connectionBidders(where: {publisherId: $publisherId}) {
    provider
    metadata
    agencyId
    agencyName
  }
}
    `;

/**
 * __useGetConnectionBiddersQuery__
 *
 * To run a query within a React component, call `useGetConnectionBiddersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectionBiddersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectionBiddersQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useGetConnectionBiddersQuery(baseOptions?: Apollo.QueryHookOptions<GetConnectionBiddersQuery, GetConnectionBiddersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConnectionBiddersQuery, GetConnectionBiddersQueryVariables>(GetConnectionBiddersDocument, options);
      }
export function useGetConnectionBiddersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConnectionBiddersQuery, GetConnectionBiddersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConnectionBiddersQuery, GetConnectionBiddersQueryVariables>(GetConnectionBiddersDocument, options);
        }
export type GetConnectionBiddersQueryHookResult = ReturnType<typeof useGetConnectionBiddersQuery>;
export type GetConnectionBiddersLazyQueryHookResult = ReturnType<typeof useGetConnectionBiddersLazyQuery>;
export type GetConnectionBiddersQueryResult = Apollo.QueryResult<GetConnectionBiddersQuery, GetConnectionBiddersQueryVariables>;
export const ByProviderStatsDocument = gql`
    query ByProviderStats($startDate: DateTime!, $endDate: DateTime!, $publisherId: Int) {
  byProviderStats(
    where: {startDate: $startDate, endDate: $endDate, publisherId: $publisherId}
  ) {
    feedProvider
    dbValidatedEarning
    cliValidatedEarning
    totalValidatedEarning
  }
  byProviderStatsTotals: byProviderStats(
    where: {startDate: $startDate, endDate: $endDate, totals: true, publisherId: $publisherId}
  ) {
    dbValidatedEarning
    cliValidatedEarning
    totalValidatedEarning
  }
}
    `;

/**
 * __useByProviderStatsQuery__
 *
 * To run a query within a React component, call `useByProviderStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useByProviderStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useByProviderStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useByProviderStatsQuery(baseOptions: Apollo.QueryHookOptions<ByProviderStatsQuery, ByProviderStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ByProviderStatsQuery, ByProviderStatsQueryVariables>(ByProviderStatsDocument, options);
      }
export function useByProviderStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ByProviderStatsQuery, ByProviderStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ByProviderStatsQuery, ByProviderStatsQueryVariables>(ByProviderStatsDocument, options);
        }
export type ByProviderStatsQueryHookResult = ReturnType<typeof useByProviderStatsQuery>;
export type ByProviderStatsLazyQueryHookResult = ReturnType<typeof useByProviderStatsLazyQuery>;
export type ByProviderStatsQueryResult = Apollo.QueryResult<ByProviderStatsQuery, ByProviderStatsQueryVariables>;
export const BySingleProviderStatsDocument = gql`
    query BySingleProviderStats($startDate: DateTime!, $endDate: DateTime!, $publisherId: Int, $provider: String) {
  byProviderStatsTotals: byProviderStats(
    where: {startDate: $startDate, endDate: $endDate, totals: true, publisherId: $publisherId, feedProvider: $provider}
  ) {
    dbValidatedEarning
    cliValidatedEarning
    totalValidatedEarning
  }
}
    `;

/**
 * __useBySingleProviderStatsQuery__
 *
 * To run a query within a React component, call `useBySingleProviderStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBySingleProviderStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBySingleProviderStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      publisherId: // value for 'publisherId'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useBySingleProviderStatsQuery(baseOptions: Apollo.QueryHookOptions<BySingleProviderStatsQuery, BySingleProviderStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BySingleProviderStatsQuery, BySingleProviderStatsQueryVariables>(BySingleProviderStatsDocument, options);
      }
export function useBySingleProviderStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BySingleProviderStatsQuery, BySingleProviderStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BySingleProviderStatsQuery, BySingleProviderStatsQueryVariables>(BySingleProviderStatsDocument, options);
        }
export type BySingleProviderStatsQueryHookResult = ReturnType<typeof useBySingleProviderStatsQuery>;
export type BySingleProviderStatsLazyQueryHookResult = ReturnType<typeof useBySingleProviderStatsLazyQuery>;
export type BySingleProviderStatsQueryResult = Apollo.QueryResult<BySingleProviderStatsQuery, BySingleProviderStatsQueryVariables>;
export const BySiteStatsDocument = gql`
    query BySiteStats($startDate: DateTime!, $endDate: DateTime!) {
  bySiteStats(startDate: $startDate, endDate: $endDate) {
    site {
      id
      name
    }
    googleImpressions
    googleEstimatedEarning
    googleValidatedEarning
    googleEstimatedCpm
    totalImpressions
    totalEstimatedEarning
    totalValidatedEarning
    totalEstimatedCpm
    dbImpressions
    dbEstimatedEarning
    dbValidatedEarning
    dbEstimatedCpm
    cliImpressions
    cliEstimatedEarning
    cliValidatedEarning
    cliEstimatedCpm
  }
  bySiteStatsTotals: bySiteStats(
    startDate: $startDate
    endDate: $endDate
    totals: true
  ) {
    googleImpressions
    googleEstimatedEarning
    googleValidatedEarning
    googleEstimatedCpm
    totalImpressions
    totalEstimatedEarning
    totalValidatedEarning
    totalEstimatedCpm
    dbImpressions
    dbEstimatedEarning
    dbValidatedEarning
    dbEstimatedCpm
    cliImpressions
    cliEstimatedEarning
    cliValidatedEarning
    cliEstimatedCpm
  }
}
    `;

/**
 * __useBySiteStatsQuery__
 *
 * To run a query within a React component, call `useBySiteStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBySiteStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBySiteStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useBySiteStatsQuery(baseOptions: Apollo.QueryHookOptions<BySiteStatsQuery, BySiteStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BySiteStatsQuery, BySiteStatsQueryVariables>(BySiteStatsDocument, options);
      }
export function useBySiteStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BySiteStatsQuery, BySiteStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BySiteStatsQuery, BySiteStatsQueryVariables>(BySiteStatsDocument, options);
        }
export type BySiteStatsQueryHookResult = ReturnType<typeof useBySiteStatsQuery>;
export type BySiteStatsLazyQueryHookResult = ReturnType<typeof useBySiteStatsLazyQuery>;
export type BySiteStatsQueryResult = Apollo.QueryResult<BySiteStatsQuery, BySiteStatsQueryVariables>;
export const ConnectionsDocument = gql`
    query Connections($siteId: [Int!]) {
  connections(where: {sourceUID: {in: $siteId}}) {
    id
    metadata
    placement {
      id
    }
    bidder {
      id
    }
  }
}
    `;

/**
 * __useConnectionsQuery__
 *
 * To run a query within a React component, call `useConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options);
      }
export function useConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options);
        }
export type ConnectionsQueryHookResult = ReturnType<typeof useConnectionsQuery>;
export type ConnectionsLazyQueryHookResult = ReturnType<typeof useConnectionsLazyQuery>;
export type ConnectionsQueryResult = Apollo.QueryResult<ConnectionsQuery, ConnectionsQueryVariables>;
export const ConnectionsPageDocument = gql`
    query ConnectionsPage($siteId: Int!) {
  placements(where: {siteId: {equals: $siteId}}) {
    id
    code
    divId
    googleCode
    networkCode
    metadata
  }
  site(where: {id: $siteId}) {
    id
    name
  }
  connectionBidders(where: {siteId: $siteId}) {
    provider
    metadata
    agencyId
    agencyName
  }
}
    `;

/**
 * __useConnectionsPageQuery__
 *
 * To run a query within a React component, call `useConnectionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsPageQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useConnectionsPageQuery(baseOptions: Apollo.QueryHookOptions<ConnectionsPageQuery, ConnectionsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionsPageQuery, ConnectionsPageQueryVariables>(ConnectionsPageDocument, options);
      }
export function useConnectionsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsPageQuery, ConnectionsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionsPageQuery, ConnectionsPageQueryVariables>(ConnectionsPageDocument, options);
        }
export type ConnectionsPageQueryHookResult = ReturnType<typeof useConnectionsPageQuery>;
export type ConnectionsPageLazyQueryHookResult = ReturnType<typeof useConnectionsPageLazyQuery>;
export type ConnectionsPageQueryResult = Apollo.QueryResult<ConnectionsPageQuery, ConnectionsPageQueryVariables>;
export const PageConnectionsDocument = gql`
    query PageConnections($siteId: Int!, $feedProvider: String!, $agencyId: Int!) {
  connectionsPage(
    where: {siteId: $siteId, feedProvider: $feedProvider, agencyId: $agencyId}
  ) {
    agencyId
    feedProvider
    placementId
    metadata
  }
}
    `;

/**
 * __usePageConnectionsQuery__
 *
 * To run a query within a React component, call `usePageConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageConnectionsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      feedProvider: // value for 'feedProvider'
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function usePageConnectionsQuery(baseOptions: Apollo.QueryHookOptions<PageConnectionsQuery, PageConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageConnectionsQuery, PageConnectionsQueryVariables>(PageConnectionsDocument, options);
      }
export function usePageConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageConnectionsQuery, PageConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageConnectionsQuery, PageConnectionsQueryVariables>(PageConnectionsDocument, options);
        }
export type PageConnectionsQueryHookResult = ReturnType<typeof usePageConnectionsQuery>;
export type PageConnectionsLazyQueryHookResult = ReturnType<typeof usePageConnectionsLazyQuery>;
export type PageConnectionsQueryResult = Apollo.QueryResult<PageConnectionsQuery, PageConnectionsQueryVariables>;
export const CreateBulkConnectionsDocument = gql`
    mutation CreateBulkConnections($data: [CreateBulkConnectionsInput!]) {
  createBulkConnections(data: $data) {
    id
  }
}
    `;
export type CreateBulkConnectionsMutationFn = Apollo.MutationFunction<CreateBulkConnectionsMutation, CreateBulkConnectionsMutationVariables>;

/**
 * __useCreateBulkConnectionsMutation__
 *
 * To run a mutation, you first call `useCreateBulkConnectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkConnectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkConnectionsMutation, { data, loading, error }] = useCreateBulkConnectionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBulkConnectionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateBulkConnectionsMutation, CreateBulkConnectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBulkConnectionsMutation, CreateBulkConnectionsMutationVariables>(CreateBulkConnectionsDocument, options);
      }
export type CreateBulkConnectionsMutationHookResult = ReturnType<typeof useCreateBulkConnectionsMutation>;
export type CreateBulkConnectionsMutationResult = Apollo.MutationResult<CreateBulkConnectionsMutation>;
export type CreateBulkConnectionsMutationOptions = Apollo.BaseMutationOptions<CreateBulkConnectionsMutation, CreateBulkConnectionsMutationVariables>;
export const DailySiteStatsDocument = gql`
    query DailySiteStats($startDate: DateTime!, $endDate: DateTime!, $id: Int!) {
  dailySiteStats(siteId: $id, startDate: $startDate, endDate: $endDate) {
    datetime
    googleImpressions
    googleEstimatedEarning
    googleValidatedEarning
    googleEstimatedCpm
    totalImpressions
    totalEstimatedEarning
    totalValidatedEarning
    totalEstimatedCpm
    dbImpressions
    dbEstimatedEarning
    dbValidatedEarning
    dbEstimatedCpm
    cliImpressions
    cliEstimatedEarning
    cliValidatedEarning
    cliEstimatedCpm
  }
  dailySiteStatsTotals: dailySiteStats(
    siteId: $id
    startDate: $startDate
    endDate: $endDate
    totals: true
  ) {
    googleImpressions
    googleEstimatedEarning
    googleValidatedEarning
    googleEstimatedCpm
    totalImpressions
    totalEstimatedEarning
    totalValidatedEarning
    totalEstimatedCpm
    dbImpressions
    dbEstimatedEarning
    dbValidatedEarning
    dbEstimatedCpm
    cliImpressions
    cliEstimatedEarning
    cliValidatedEarning
    cliEstimatedCpm
  }
  site(where: {id: $id}) {
    id
    name
  }
}
    `;

/**
 * __useDailySiteStatsQuery__
 *
 * To run a query within a React component, call `useDailySiteStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailySiteStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailySiteStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDailySiteStatsQuery(baseOptions: Apollo.QueryHookOptions<DailySiteStatsQuery, DailySiteStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailySiteStatsQuery, DailySiteStatsQueryVariables>(DailySiteStatsDocument, options);
      }
export function useDailySiteStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailySiteStatsQuery, DailySiteStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailySiteStatsQuery, DailySiteStatsQueryVariables>(DailySiteStatsDocument, options);
        }
export type DailySiteStatsQueryHookResult = ReturnType<typeof useDailySiteStatsQuery>;
export type DailySiteStatsLazyQueryHookResult = ReturnType<typeof useDailySiteStatsLazyQuery>;
export type DailySiteStatsQueryResult = Apollo.QueryResult<DailySiteStatsQuery, DailySiteStatsQueryVariables>;
export const DailyStatsDocument = gql`
    query DailyStats($startDate: DateTime!, $endDate: DateTime!) {
  dailyStats(startDate: $startDate, endDate: $endDate) {
    datetime
    googleImpressions
    googleEstimatedEarning
    googleValidatedEarning
    googleEstimatedCpm
    totalImpressions
    totalEstimatedEarning
    totalValidatedEarning
    totalEstimatedCpm
    dbImpressions
    dbEstimatedEarning
    dbValidatedEarning
    dbEstimatedCpm
    cliImpressions
    cliEstimatedEarning
    cliValidatedEarning
    cliEstimatedCpm
  }
  dailyStatsTotals: dailyStats(
    startDate: $startDate
    endDate: $endDate
    totals: true
  ) {
    googleImpressions
    googleEstimatedEarning
    googleValidatedEarning
    googleEstimatedCpm
    totalImpressions
    totalEstimatedEarning
    totalValidatedEarning
    totalEstimatedCpm
    dbImpressions
    dbEstimatedEarning
    dbValidatedEarning
    dbEstimatedCpm
    cliImpressions
    cliEstimatedEarning
    cliValidatedEarning
    cliEstimatedCpm
  }
}
    `;

/**
 * __useDailyStatsQuery__
 *
 * To run a query within a React component, call `useDailyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDailyStatsQuery(baseOptions: Apollo.QueryHookOptions<DailyStatsQuery, DailyStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyStatsQuery, DailyStatsQueryVariables>(DailyStatsDocument, options);
      }
export function useDailyStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyStatsQuery, DailyStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyStatsQuery, DailyStatsQueryVariables>(DailyStatsDocument, options);
        }
export type DailyStatsQueryHookResult = ReturnType<typeof useDailyStatsQuery>;
export type DailyStatsLazyQueryHookResult = ReturnType<typeof useDailyStatsLazyQuery>;
export type DailyStatsQueryResult = Apollo.QueryResult<DailyStatsQuery, DailyStatsQueryVariables>;
export const PlacementsDocument = gql`
    query Placements($siteId: Int!) {
  placements(where: {siteId: {equals: $siteId}}) {
    id
    code
    divId
    googleCode
    networkCode
    metadata
  }
}
    `;

/**
 * __usePlacementsQuery__
 *
 * To run a query within a React component, call `usePlacementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function usePlacementsQuery(baseOptions: Apollo.QueryHookOptions<PlacementsQuery, PlacementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlacementsQuery, PlacementsQueryVariables>(PlacementsDocument, options);
      }
export function usePlacementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacementsQuery, PlacementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlacementsQuery, PlacementsQueryVariables>(PlacementsDocument, options);
        }
export type PlacementsQueryHookResult = ReturnType<typeof usePlacementsQuery>;
export type PlacementsLazyQueryHookResult = ReturnType<typeof usePlacementsLazyQuery>;
export type PlacementsQueryResult = Apollo.QueryResult<PlacementsQuery, PlacementsQueryVariables>;
export const CreatePlacementDocument = gql`
    mutation CreatePlacement($siteId: Int!, $code: String!, $divId: String!, $googleCode: String!, $networkCode: String!, $metadata: String!, $details: String!) {
  createPlacement(
    data: {siteId: $siteId, code: $code, divId: $divId, googleCode: $googleCode, networkCode: $networkCode, metadata: $metadata, details: $details}
  ) {
    id
    code
    divId
    googleCode
    networkCode
    metadata
  }
}
    `;
export type CreatePlacementMutationFn = Apollo.MutationFunction<CreatePlacementMutation, CreatePlacementMutationVariables>;

/**
 * __useCreatePlacementMutation__
 *
 * To run a mutation, you first call `useCreatePlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlacementMutation, { data, loading, error }] = useCreatePlacementMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      code: // value for 'code'
 *      divId: // value for 'divId'
 *      googleCode: // value for 'googleCode'
 *      networkCode: // value for 'networkCode'
 *      metadata: // value for 'metadata'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useCreatePlacementMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlacementMutation, CreatePlacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlacementMutation, CreatePlacementMutationVariables>(CreatePlacementDocument, options);
      }
export type CreatePlacementMutationHookResult = ReturnType<typeof useCreatePlacementMutation>;
export type CreatePlacementMutationResult = Apollo.MutationResult<CreatePlacementMutation>;
export type CreatePlacementMutationOptions = Apollo.BaseMutationOptions<CreatePlacementMutation, CreatePlacementMutationVariables>;
export const PublishersDocument = gql`
    query Publishers {
  publishers {
    id
    name
  }
}
    `;

/**
 * __usePublishersQuery__
 *
 * To run a query within a React component, call `usePublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublishersQuery(baseOptions?: Apollo.QueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
      }
export function usePublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
        }
export type PublishersQueryHookResult = ReturnType<typeof usePublishersQuery>;
export type PublishersLazyQueryHookResult = ReturnType<typeof usePublishersLazyQuery>;
export type PublishersQueryResult = Apollo.QueryResult<PublishersQuery, PublishersQueryVariables>;
export const GetSiteDocument = gql`
    query GetSite($id: Int!) {
  site(where: {id: $id}) {
    id
    name
    publisherId
    feedtimeout
  }
}
    `;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions: Apollo.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
      }
export function useGetSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = Apollo.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const SitesDocument = gql`
    query Sites {
  sites {
    id
    name
  }
}
    `;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSitesQuery(baseOptions?: Apollo.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
      }
export function useSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
        }
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = Apollo.QueryResult<SitesQuery, SitesQueryVariables>;
export const CreateSiteDocument = gql`
    mutation CreateSite($name: String!, $feedtimeout: Int!, $publisherId: Int!) {
  createSite(
    data: {name: $name, publisherId: $publisherId, feedtimeout: $feedtimeout}
  ) {
    id
    name
  }
}
    `;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      feedtimeout: // value for 'feedtimeout'
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const UpdateSiteDocument = gql`
    mutation UpdateSite($name: String!, $feedtimeout: Int!, $publisherId: Int!, $id: Int!) {
  updateSite(
    data: {name: $name, publisherId: $publisherId, feedtimeout: $feedtimeout}
    where: {id: $id}
  ) {
    id
    name
  }
}
    `;
export type UpdateSiteMutationFn = Apollo.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      feedtimeout: // value for 'feedtimeout'
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
      }
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = Apollo.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const GetProfileDocument = gql`
    query GetProfile {
  userProfile {
    id
    fname
    lname
    email
    superadmin
    permissions {
      agencyId
    }
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    fname
    lname
    email
    superadmin
    active
    deleted
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const ResetUserPasswordDocument = gql`
    mutation ResetUserPassword($password: String!, $token: String!) {
  resetUserPassword(data: {password: $password}, where: {token: $token})
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(where: {email: $email})
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;