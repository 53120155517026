import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  useBySingleProviderStatsLazyQuery,
  useGetConnectionBiddersLazyQuery,
  usePublishersQuery,
  useSetBidderRevenueMutation,
} from '@snapshot/data-access';
import * as yup from 'yup';
import moment, { Moment } from 'moment';

import { InputAdornment } from '@material-ui/core';
import { FormTextField } from './form-text-field/form-text-field';
import { LoadingButton } from '../loading-button/loading-button';
import { FormLayout } from '../layout/form-layout';
import { FormSelectField } from './form-select-field/form-select-field';
import { FormDateField } from './form-date-field/form-date-field';

interface UpdateRevenueFormParams {
  provider: string;
  publisherId: number;
  revenue: number;
  date: string;
}

export const UpdateRevenue: React.FunctionComponent = () => {
  const { data: publishersData, loading: publishersLoading } = usePublishersQuery();

  const [setRevenue, { loading: setRevenueLoading }] = useSetBidderRevenueMutation();

  const [publisherId, setPublisherId] = useState<number | null>(null);
  const [provider, setProvider] = useState<string | null>(null);
  const [date, setDate] = useState<string>(moment().format('YYYY-MM'));
  const [existingRevenue, setExistingRevenue] = useState<number>(0);

  const [
    fetchConnectionBidders,
    { data: biddersData, loading: biddersLoading },
  ] = useGetConnectionBiddersLazyQuery();

  const [
    getStats,
    { data: statsData, loading: statsLoading },
  ] = useBySingleProviderStatsLazyQuery();

  const initialValues: UpdateRevenueFormParams = {
    provider: '',
    publisherId: 0,
    revenue: 0,
    date: moment().format('YYYY-MM'),
  };

  const validationSchema = yup.object().shape({
    provider: yup.string().required('Provider is required'),
    publisherId: yup.number().required('Publisher is required'),
    revenue: yup.number().required('Revenue is required'),
    date: yup.string().required('Date is required'),
  });

  const onSubmit = async (data: typeof initialValues) => {
    const feedProvider = data.provider.split('-')[0];
    const agency = parseInt(data.provider.split('-')[1], 10);

    await setRevenue({
      variables: {
        ...data,
        revenue: data.revenue.toString(),
        provider: feedProvider,
        agencyId: agency,
      },
    });

    getStats({
      variables: {
        startDate: moment(data.date).startOf('month').format('YYYY-MM-DDTHH:mm:ss+00:00'),
        endDate: moment(data.date).endOf('month').format('YYYY-MM-DDTHH:mm:ss+00:00'),
        publisherId: data.publisherId,
        provider: feedProvider,
      },
    });
  };

  const onChangePublisher = (newPublisherId: number) => {
    setPublisherId(newPublisherId);
    fetchConnectionBidders({
      variables: {
        publisherId: newPublisherId,
      },
    });
  };

  const onChangeProvider = (newProvider: string) => {
    setProvider(newProvider);
  };

  const onChangeDate = (newDate: Moment) => {
    setDate(newDate.format('YYYY-MM'));
  };

  useEffect(() => {
    if (provider && publisherId && date) {
      getStats({
        variables: {
          startDate: moment(date).startOf('month').format('YYYY-MM-DDTHH:mm:ss+00:00'),
          endDate: moment(date).endOf('month').format('YYYY-MM-DDTHH:mm:ss+00:00'),
          publisherId,
          provider: provider.split('-')[0],
        },
      });
    }
  }, [publisherId, provider, date, getStats]);

  const publishers = publishersData?.publishers || [];

  const bidderProviders = biddersData?.connectionBidders || [];

  const statData = statsData?.byProviderStatsTotals;

  let revenueValue = parseFloat(statData?.[0]?.cliValidatedEarning || '0').toFixed(2);

  if (provider && provider.split('-')[1] === '2522495') {
    revenueValue = parseFloat(statData?.[0]?.dbValidatedEarning || '0').toFixed(2);
  }

  return (
    <FormLayout title="Edit publisher bidder revenue">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <FormSelectField
            name="publisherId"
            label="Publisher Id"
            type="number"
            options={publishers.map((publisher) => ({
              label: publisher.name,
              value: publisher.id,
            }))}
            onChange={(e) => {
              onChangePublisher(parseInt(e.target.value, 10));
            }}
          />
          <FormSelectField
            name="provider"
            label="Bidder Provider"
            type="text"
            disabled={!biddersData || biddersLoading}
            options={bidderProviders.map((bidderProvider) => ({
              label: `${bidderProvider.agencyName} - ${bidderProvider.provider}`,
              value: `${bidderProvider.provider}-${bidderProvider.agencyId}`,
            }))}
            onChange={(e) => {
              onChangeProvider(e.target.value);
            }}
          />
          <FormDateField
            onChange={(v) => {
              onChangeDate(v);
            }}
            views={['year', 'month']}
            name="date"
            label="Date"
            format="YYYY-MM"
          />
          <p>
            Existing Revenue: {statsLoading ? 'Loading...' : `$${revenueValue}`}
            <br />
            <small>Note: The existing revenue above INCLUDES the additional revenue below.</small>
          </p>
          <FormTextField
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            name="revenue"
            label="Revenue (USD)"
            type="number"
          />
          <LoadingButton
            loading={publishersLoading || biddersLoading || setRevenueLoading}
            type="submit"
            text="Update Revenue"
          />
        </Form>
      </Formik>
    </FormLayout>
  );
};
