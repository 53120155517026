import React from 'react';
import { createStyles, ListItem, ListItemProps, makeStyles } from '@material-ui/core';
import { NavLink as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface ListItemLinkProps {
  to: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ListItemLink = ({
  to,
  children,
  className,
  onClick,
}: React.PropsWithChildren<ListItemLinkProps>) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );
  return (
    <ListItem onClick={onClick} className={className} disableRipple button component={renderLink}>
      {children}
    </ListItem>
  );
};
