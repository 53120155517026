import {
  createStyles,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { ListItemLink } from '../list-item-link/list-item-link';
import type { NavLinkItem } from '../ui-navigation';

const useStyles = makeStyles((theme) =>
  createStyles({
    listItemLink: {
      color: theme.palette.secondary.main,
      backgroundColor: 'inherit',
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'inherit',
      },
      transition: `${theme.transitions.duration.short}ms`,
    },
    listItemIcon: {
      color: 'inherit',
    },
    listItemText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

/* eslint-disable-next-line */
export interface NavDrawerItemProps extends NavLinkItem {
  closeDrawer: () => void;
}

export function NavDrawerItem({ to, icon, label, closeDrawer }: NavDrawerItemProps) {
  const classes = useStyles();
  return (
    <ListItemLink onClick={closeDrawer} className={classes.listItemLink} to={to}>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          className: classes.listItemText,
        }}
      />
    </ListItemLink>
  );
}
