import { Box, Link, Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { useRequestPasswordResetMutation } from '@snapshot/data-access';
import { FieldMap, FormContainer, emailField } from '../components/forms';
import { AuthLayout } from '../components/layout/auth';

type ForgotPasswordFormData = {
  email: string;
};

export const ForgotPasswordPage: React.FunctionComponent = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [requestPasswordReset, { loading }] = useRequestPasswordResetMutation();

  const onSubmit = async (values: ForgotPasswordFormData) => {
    await requestPasswordReset({
      variables: {
        email: values.email,
      },
    })
      .then(() => {
        setErrorMessage('');
        return setSuccessMessage('A password reset email will be sent if the email exists.');
      })
      .catch(() => {
        setSuccessMessage('');
        setErrorMessage(
          'There was an error setting request a password reset email Please try again.',
        );
      });
  };

  const fields: FieldMap<ForgotPasswordFormData> = {
    email: emailField(true),
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <>
      <Snackbar
        open={!!errorMessage || !!successMessage}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity={errorMessage ? 'error' : 'success'} onClose={handleClose}>
          {errorMessage || successMessage}
        </Alert>
      </Snackbar>
      <AuthLayout>
        <div className="text-center text-black-50 mb-3">
          <span>Please enter your email below to request a password reset email:</span>
        </div>
        <FormContainer loading={loading} onSubmit={onSubmit} fields={fields}>
          <Box>
            <Link href="/login">Click here</Link> to return to the login page.
          </Box>
        </FormContainer>
      </AuthLayout>
    </>
  );
};
