import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { Moment } from 'moment';
import { makeStyles, PropTypes } from '@material-ui/core';

export interface DatePickerProps {
  label: string;
  value: Moment;
  setValue: (value: Moment) => void;
  color?: PropTypes.Color;
  hasError?: boolean;
  format?: string;
  views?: KeyboardDatePickerProps['views'];
}

const useStyles = makeStyles({
  datePicker: {
    width: 150,
  },
});

export const DatePicker = ({
  label,
  value,
  setValue,
  hasError = false,
  color = 'default',
  format = 'YYYY-MM-DD',
  views,
}: DatePickerProps) => {
  const classes = useStyles();
  const handleDateChange = (date: Moment | null) => {
    if (date) {
      setValue(date);
    }
  };
  // dumb but whatever
  const datePickerColor = color === 'primary' || color === 'secondary' ? color : undefined;
  return (
    <KeyboardDatePicker
      views={views}
      className={classes.datePicker}
      color={datePickerColor}
      fullWidth={false}
      disableToolbar
      variant="inline"
      label={label}
      InputAdornmentProps={{ position: 'start' }}
      InputProps={{
        disableUnderline: true,
      }}
      value={value}
      onChange={handleDateChange}
      autoOk
      format={format}
      error={hasError}
    />
  );
};
