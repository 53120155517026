import React from 'react';
import { Formik, FormikConfig, Form } from 'formik';
import { useGetSiteQuery, usePublishersQuery, useUpdateSiteMutation } from '@snapshot/data-access';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { FormTextField } from './form-text-field/form-text-field';
import { LoadingButton } from '../loading-button/loading-button';
import { FormLayout } from '../layout/form-layout';
import { FormSelectField } from './form-select-field/form-select-field';

interface EditSiteParams {
  id: string;
}

export const EditSite: React.FunctionComponent = () => {
  // Get site id from route params
  const { id: rawId } = useParams<EditSiteParams>();
  const id = parseInt(rawId, 10);

  // Get the site
  const { data: getSiteData, loading: getSiteLoading } = useGetSiteQuery({
    variables: {
      id,
    },
  });

  // Get the publishers
  const { data: publishersData, loading: publishersLoading } = usePublishersQuery();

  const [updateSite, { loading: updateSiteLoading }] = useUpdateSiteMutation();

  const site = getSiteData?.site;
  const publishers = publishersData?.publishers || [];

  if (!site) {
    return null;
  }

  const initialValues = {
    name: site.name,
    publisherId: site.publisherId,
    feedtimeout: site.feedtimeout,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    publisherId: yup.number().required('Publisher is required'),
    feedtimeout: yup.number().required('Feed timeout is required'),
  });

  const onSubmit = async (siteData: { name: string; publisherId: number; feedtimeout: number }) => {
    await updateSite({
      variables: {
        ...siteData,
        id,
      },
    });
  };

  return (
    <FormLayout title={`Edit ${site.name} - ${site.id}`}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <FormTextField name="name" label="Name" type="text" />

          <FormSelectField
            name="publisherId"
            label="Publisher Id"
            type="number"
            options={publishers.map((publisher) => ({
              label: publisher.name,
              value: publisher.id,
            }))}
          />

          <FormTextField name="feedtimeout" label="Feed Timeout" type="number" />

          <LoadingButton
            loading={updateSiteLoading || getSiteLoading || publishersLoading}
            type="submit"
            text="Update Site"
          />
        </Form>
      </Formik>
    </FormLayout>
  );
};
