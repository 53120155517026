import React from 'react';
import { Menu, Button, List, ListItem, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link as RouterLink, NavLinkProps, useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonIcon from '@material-ui/icons/Person';
import { authLogout } from '../../../store/authentication';
import { RootState } from '../../../store/root-reducer';
import { useAppDispatch } from '../../../store';

const AdapterLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <RouterLink innerRef={ref as any} {...props} />
));

export const HeaderUserbox: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onClickLogout = (): void => {
    history.push('/login');
    dispatch(authLogout());
  };

  const user = useSelector((state: RootState) => state.auth.user);

  if (user) {
    return (
      <>
        <Button
          color="inherit"
          onClick={handleClick}
          className="px-3 text-left btn-inverse d-flex align-items-center"
        >
          <div className="d-none d-sm-block pl-3">
            <div className="font-weight-bold pt-2 line-height-1">{`${user.fname} ${user.lname}`}</div>
            <span className="text-white-50">{user.email}</span>
          </div>
          <div className="d-sm-none">
            <PersonIcon />
          </div>
          <span className="pl-1 pl-xl-3">
            <ArrowDropDownIcon className="opacity-5" />
          </span>
        </Button>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          onClose={handleClose}
          className="ml-2"
        >
          <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
            <List className="text-left bg-transparent d-flex flex-column pt-0">
              <div className="pl-3 ">
                <div className="font-weight-bold pt-2 line-height-1">{`${user.fname} ${user.lname}`}</div>
                <span className="text-black-50 text-center">{user.email}</span>
              </div>
              <Divider className="w-100 mt-2" />
              <ListItem button to="/profile" component={AdapterLink}>
                My Account
              </ListItem>
              <ListItem button onClick={onClickLogout}>
                Logout
              </ListItem>
            </List>
          </div>
        </Menu>
      </>
    );
  }
  return <></>;
};
