import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import React from 'react';
import { useField } from 'formik';

interface ExtraFormFields {
  name: string;
  label: string;
  hasError?: boolean;
  errorMessage?: string;
  fluidAvailable?: boolean;
}

type FormDimensionsFieldProps = Omit<
  Partial<TextFieldProps>,
  'id' | 'value' | 'onChange' | 'error' | 'helperText'
> &
  ExtraFormFields;

export const FormDimensionsField: React.FunctionComponent<FormDimensionsFieldProps> = ({
  name,
  hasError,
  errorMessage,
  fluidAvailable = false,
  ...rest
}) => {
  const [field, meta, { setValue }] = useField({ name });
  const [isFluid, setIsFluid] = React.useState(false);

  const handleFluidChange = (value: boolean) => {
    if (value) {
      setValue('fluid');
    } else {
      setValue([0, 0]);
    }
    setIsFluid(value);
  };

  return (
    <FormControl className="mb-3">
      <Box display="flex" flexDirection="row" alignItems="center">
        {fluidAvailable ? (
          <Box alignSelf="center" alignItems="center">
            <FormControlLabel
              control={<Switch checked={isFluid} onChange={() => handleFluidChange(!isFluid)} />}
              label="Fluid"
            />
          </Box>
        ) : null}
        {isFluid ? null : (
          <>
            <TextField
              {...rest}
              id={`${name}[0]`}
              name={`${name}[0]`}
              label={`${rest.label} Width`}
              type="number"
              value={field.value[0]}
              onChange={field.onChange}
              error={hasError || (meta.touched && Boolean(meta.error))}
              helperText={(meta.touched && meta.error) || errorMessage}
              onBlur={field.onBlur}
              className="mr-2"
            />
            <TextField
              {...rest}
              id={`${name}[1]`}
              name={`${name}[1]`}
              label={`${rest.label} Height`}
              type="number"
              value={field.value[1]}
              onChange={field.onChange}
              error={hasError || (meta.touched && Boolean(meta.error))}
              helperText={(meta.touched && meta.error) || errorMessage}
              onBlur={field.onBlur}
            />
          </>
        )}
      </Box>
    </FormControl>
  );
};
