import React from 'react';

/* eslint-disable-next-line */
export interface UiLayoutsProps {}

export function UiLayouts(props: UiLayoutsProps) {
  return (
    <div>
      <h1>Welcome to ui-layouts!</h1>
    </div>
  );
}
