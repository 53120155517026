import React from 'react';
import { Grid } from '@material-ui/core';
import { UpdateRevenue } from '../components/forms/update-revenue';

export const UpdateRevenuePage = () => (
  <Grid container>
    <Grid item xs={12}>
      <UpdateRevenue />
    </Grid>
  </Grid>
);
