import React from 'react';
import { Placement } from '@snapshot/prisma-client';
import { TableCell, TableRow } from '@material-ui/core';
import { BidderInputs } from './bidder-inputs';
import type { CBidder, ConnectionMap, ConnectionMapErrors } from '../connections-page';

interface PlacementRowProps {
  bidders: CBidder[];
  placement: Pick<Placement, 'id' | 'code'>;
  updateConnection: (
    metadata: Record<string, unknown>,
    bidder: CBidder,
    placementId: number,
  ) => void;
  connectionMap: ConnectionMap;
  connectionMapErrors: ConnectionMapErrors;
}

export const PlacementRow: React.FunctionComponent<PlacementRowProps> = ({
  bidders,
  updateConnection,
  placement,
  connectionMap,
  connectionMapErrors,
}) => {
  const bidderFieldCells = bidders.map((bidder) => {
    const metadata = connectionMap[bidder.agencyId]?.[bidder.provider]?.metadata?.[placement.id];
    if (!metadata) {
      return null;
    }
    const updateConnectionMetadata = (field: string, value: unknown) => {
      updateConnection(
        {
          ...metadata,
          [field]: value,
        },
        bidder,
        placement.id,
      );
    };

    return (
      <TableCell key={`${bidder.provider}${bidder.agencyName}`}>
        <BidderInputs
          cErrors={
            connectionMapErrors?.[bidder.agencyId]?.[bidder.provider]?.metadata?.[placement.id]
          }
          bidder={bidder}
          metadata={metadata}
          updateConnectionMetadata={updateConnectionMetadata}
        />
      </TableCell>
    );
  });

  return (
    <TableRow>
      <TableCell>{placement.id}</TableCell>
      <TableCell>{placement.code}</TableCell>
      {bidderFieldCells}
    </TableRow>
  );
};
