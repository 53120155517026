import { Button, CardContent, Grid, TextField, Card } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/root-reducer';

export const ProfilePage: React.FunctionComponent = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const [firstName, setFirstName] = useState(user?.fname);
  const [lastName, setLastName] = useState(user?.lname);

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event?.preventDefault();
  };

  if (!user) {
    return <></>;
  }
  return (
    <Grid container direction="row" justify="center">
      <Grid item xs={12} sm={9} md={6}>
        <h1>My Account</h1>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                disabled
                id="first-name"
                fullWidth
                label="First Name"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
              <br />
              <br />
              <TextField
                disabled
                fullWidth
                id="last-name"
                label="Last Name"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
              <br />
              <br />
              <br />
              <br />
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Button disabled variant="contained" color="secondary" type="submit">
                    Submit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled
                    variant="outlined"
                    color="secondary"
                    onClick={() => setShowModal(true)}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
