import { FormControl, MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { useField } from 'formik';

interface ExtraFormFields {
  name: string;
  label: string;
  type: string;
  hasError?: boolean;
  errorMessage?: string;
  options: {
    value: number | string;
    label: string;
  }[];
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

type FormSelectFieldProps = Omit<
  Partial<TextFieldProps>,
  'id' | 'value' | 'onChange' | 'error' | 'helperText'
> &
  ExtraFormFields;

export const FormSelectField: React.FunctionComponent<FormSelectFieldProps> = ({
  name,
  type,
  hasError,
  errorMessage,
  options,
  onChange,
  ...rest
}) => {
  const [field, meta] = useField({ name, type });
  let placeholderValue: string | number = 0;
  if (typeof options[0]?.value === 'string') {
    placeholderValue = '';
  }
  return (
    <FormControl className="w-100 mb-3">
      <TextField
        select
        {...rest}
        id={name}
        name={name}
        type={type}
        value={field.value}
        onChange={(e) => {
          field.onChange(e);
          onChange?.(e);
        }}
        error={hasError || (meta.touched && Boolean(meta.error))}
        helperText={(meta.touched && meta.error) || errorMessage}
        onBlur={field.onBlur}
      >
        <MenuItem value={placeholderValue}>
          <em>Select</em>
        </MenuItem>
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
