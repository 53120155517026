export var secondaryTheme = {"r":61,"g":73,"b":119,"a":1,"rgba":"rgba(61, 73, 119, 1)"}
export var secondaryLight = {"r":107,"g":116,"b":166,"a":1,"rgba":"rgba(107, 116, 166, 1)"}
export var secondaryDark = {"r":13,"g":34,"b":75,"a":1,"rgba":"rgba(13, 34, 75, 1)"}
export var primaryContrastText = {"r":255,"g":255,"b":255,"a":1,"rgba":"rgba(255, 255, 255, 1)"}
export var primaryTheme = {"r":83,"g":131,"b":255,"a":1,"rgba":"rgba(83, 131, 255, 1)"}
export var primaryLight = {"r":142,"g":178,"b":255,"a":1,"rgba":"rgba(142, 178, 255, 1)"}
export var primaryDark = {"r":0,"g":87,"b":203,"a":1,"rgba":"rgba(0, 87, 203, 1)"}
export var inheritDefault1 = {"r":254,"g":254,"b":254,"a":1,"rgba":"rgba(254, 254, 254, 1)"}
export var inheritDefault2 = {"r":248,"g":249,"b":255,"a":1,"rgba":"rgba(248, 249, 255, 1)"}
export var second = {"r":7,"g":9,"b":25,"a":1,"rgba":"rgba(7, 9, 25, 1)"}
export var indigo = {"r":116,"g":32,"b":255,"a":1,"rgba":"rgba(116, 32, 255, 1)"}
export var purple = {"r":121,"g":61,"b":230,"a":1,"rgba":"rgba(121, 61, 230, 1)"}
export var pink = {"r":252,"g":38,"b":164,"a":1,"rgba":"rgba(252, 38, 164, 1)"}
export var red = {"r":248,"g":50,"b":69,"a":1,"rgba":"rgba(248, 50, 69, 1)"}
export var orange = {"r":244,"g":119,"b":46,"a":1,"rgba":"rgba(244, 119, 46, 1)"}
export var yellow = {"r":255,"g":201,"b":38,"a":1,"rgba":"rgba(255, 201, 38, 1)"}
export var green = {"r":27,"g":201,"b":67,"a":1,"rgba":"rgba(27, 201, 67, 1)"}
export var teal = {"r":24,"g":225,"b":165,"a":1,"rgba":"rgba(24, 225, 165, 1)"}
export var cyan = {"r":39,"g":220,"b":243,"a":1,"rgba":"rgba(39, 220, 243, 1)"}
export default {"secondaryTheme":{"r":61,"g":73,"b":119,"a":1,"rgba":"rgba(61, 73, 119, 1)"},"secondaryLight":{"r":107,"g":116,"b":166,"a":1,"rgba":"rgba(107, 116, 166, 1)"},"secondaryDark":{"r":13,"g":34,"b":75,"a":1,"rgba":"rgba(13, 34, 75, 1)"},"primaryContrastText":{"r":255,"g":255,"b":255,"a":1,"rgba":"rgba(255, 255, 255, 1)"},"primaryTheme":{"r":83,"g":131,"b":255,"a":1,"rgba":"rgba(83, 131, 255, 1)"},"primaryLight":{"r":142,"g":178,"b":255,"a":1,"rgba":"rgba(142, 178, 255, 1)"},"primaryDark":{"r":0,"g":87,"b":203,"a":1,"rgba":"rgba(0, 87, 203, 1)"},"inheritDefault1":{"r":254,"g":254,"b":254,"a":1,"rgba":"rgba(254, 254, 254, 1)"},"inheritDefault2":{"r":248,"g":249,"b":255,"a":1,"rgba":"rgba(248, 249, 255, 1)"},"second":{"r":7,"g":9,"b":25,"a":1,"rgba":"rgba(7, 9, 25, 1)"},"indigo":{"r":116,"g":32,"b":255,"a":1,"rgba":"rgba(116, 32, 255, 1)"},"purple":{"r":121,"g":61,"b":230,"a":1,"rgba":"rgba(121, 61, 230, 1)"},"pink":{"r":252,"g":38,"b":164,"a":1,"rgba":"rgba(252, 38, 164, 1)"},"red":{"r":248,"g":50,"b":69,"a":1,"rgba":"rgba(248, 50, 69, 1)"},"orange":{"r":244,"g":119,"b":46,"a":1,"rgba":"rgba(244, 119, 46, 1)"},"yellow":{"r":255,"g":201,"b":38,"a":1,"rgba":"rgba(255, 201, 38, 1)"},"green":{"r":27,"g":201,"b":67,"a":1,"rgba":"rgba(27, 201, 67, 1)"},"teal":{"r":24,"g":225,"b":165,"a":1,"rgba":"rgba(24, 225, 165, 1)"},"cyan":{"r":39,"g":220,"b":243,"a":1,"rgba":"rgba(39, 220, 243, 1)"}}
