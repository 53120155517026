import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import queryString from 'query-string';
import { isAuthenticatedSelector } from '../../store/authentication';
import { LoginPage } from '../../pages/login-page';
import { MainLayout } from '../layout/main-layout';
import { ProfilePage } from '../../pages/profile-page';
import { ResetPasswordPage } from '../../pages/reset-password-page';
import { ForgotPasswordPage } from '../../pages/forgot-password-page';
import { useAppSelector } from '../../store';
import { RootState } from '../../store/root-reducer';
import { StatsPage } from '../../pages/stats-page';
import { ProtectedRoute } from './protected-route';
import { PublishersPage } from '../../pages/manage/publishers-page';
import { ConnectionsPage } from '../../pages/manage/connections-page';
import { SitesPage } from '../../pages/manage/sites-page';
import { CreateSitePage } from '../manage/create-site';
import { EditSite } from '../forms/edit-site';
import { CreatePlacement } from '../forms/create-placement';
import { UpdateRevenuePage } from '../../pages/update-revenue-page';

export function Router(): JSX.Element {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  const user = useAppSelector((state: RootState) => state.auth.user);

  const getQueryParam = (search: string, param: string): string | undefined => {
    const params = new URLSearchParams(search);
    return params.get(param) || undefined;
  };

  return (
    <BrowserRouter>
      <Switch>
        {/* login with no layout */}
        <Route
          path="/login"
          render={(props) => <LoginPage path={getQueryParam(props.location.search, 'path')} />}
        />
        <Route path="/forgot-password" render={() => <ForgotPasswordPage />} />
        <Route
          path="/reset-password"
          render={(props) => (
            <ResetPasswordPage token={getQueryParam(props.location.search, 'token')} />
          )}
        />
        {/* routes with main layout */}

        <MainLayout user={user}>
          <Switch>
            <ProtectedRoute isAuthenticated={isAuthenticated} exact path="/">
              <StatsPage resolution="day" />
            </ProtectedRoute>
            <ProtectedRoute isAuthenticated={isAuthenticated} exact path="/manage/sites/create">
              <CreateSitePage />
            </ProtectedRoute>
            <ProtectedRoute isAuthenticated={!!user?.superadmin} exact path="/manage/publishers">
              <PublishersPage />
            </ProtectedRoute>
            <ProtectedRoute isAuthenticated={!!user?.superadmin} exact path="/manage/sites">
              <SitesPage />
            </ProtectedRoute>

            <ProtectedRoute
              isAuthenticated={!!user?.superadmin}
              exact
              path="/manage/sites/:id/edit"
            >
              <EditSite />
            </ProtectedRoute>
            <ProtectedRoute
              isAuthenticated={!!user?.superadmin}
              exact
              path="/manage/placements/create"
            >
              <CreatePlacement />
            </ProtectedRoute>
            <ProtectedRoute
              isAuthenticated={!!user?.superadmin}
              exact
              path="/manage/sites/:id/connections"
              component={ConnectionsPage}
            />
            <ProtectedRoute isAuthenticated={isAuthenticated} exact path="/stats">
              <StatsPage resolution="day" />
            </ProtectedRoute>
            <ProtectedRoute isAuthenticated={!!user?.superadmin} exact path="/bidder-stats">
              <StatsPage resolution="day" byProvider />
            </ProtectedRoute>
            <ProtectedRoute isAuthenticated={isAuthenticated} exact path="/daily">
              <StatsPage resolution="day" />
            </ProtectedRoute>
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/sites"
              render={(props) => (
                <StatsPage
                  moduleName="site"
                  rawId={props.match.params.id}
                  resolution="day"
                  day={queryString.parse(props.location.search).day}
                />
              )}
            />
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/sites/:id/daily"
              render={(props) => (
                <StatsPage moduleName="site" rawId={props.match.params.id} resolution="day" />
              )}
            />
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/profile"
              render={() => <ProfilePage />}
            />
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/update-revenue"
              render={() => <UpdateRevenuePage />}
            />
          </Switch>
        </MainLayout>
      </Switch>
    </BrowserRouter>
  );
}
