import React from 'react';
import { Box, lighten, TableCell, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/styles';
import type { RootState } from '../../store/root-reducer';
import { getColumnDefinition, getMappedDefinition } from '../../utils/helpers/column-definitions';
import { useAppSelector } from '../../store';
import { DataRow, DataTableContext } from '../../types/datatables';
import { AllKeys, Idx } from '../../types/helpers';
import { getSort } from '../../store/data-table/data-table-slice';

interface DataTableCellProps {
  row: DataRow;
  field: AllKeys<DataRow>;
  context: DataTableContext;
  summary?: boolean;
  value: Idx<DataRow, this['field']>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryCell: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
      borderBottom: 'none',
    },
    sorting: {
      backgroundColor: lighten(theme.palette.primary.light, 0.635),
    },
  }),
);

export const DataTableCell: React.FunctionComponent<DataTableCellProps> = ({
  row,
  field,
  context,
  summary,
  value,
}) => {
  const classes = useStyles();
  const colDef = getColumnDefinition(field);
  const sort = useAppSelector(getSort);
  const user = useAppSelector((state: RootState) => state.auth.user);
  if (!colDef) {
    return null;
  }

  const mappedDefinition = getMappedDefinition(colDef, {
    ...context,
    value,
    row,
    summary,
    user,
  });

  if (mappedDefinition.hide) {
    return null;
  }

  const formattedValue = mappedDefinition.valueFormatter || value;

  const isSorting = sort.field === mappedDefinition.sortField;

  return (
    <TableCell
      key={field}
      data-field={field}
      align={mappedDefinition.cellProps?.align}
      className={clsx(
        mappedDefinition.grouping?.replace(/ /, '-').toLowerCase().concat('-grouping-cell'),
        {
          [classes.summaryCell]: summary,
          'summary-cell': summary,
          [classes.sorting]: isSorting && !summary,
        },
      )}
      {...mappedDefinition.cellProps}
    >
      <Box className="d-inline">{mappedDefinition?.renderCell || formattedValue}</Box>
    </TableCell>
  );
};
