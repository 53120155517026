import { configureStore, Action } from '@reduxjs/toolkit';
import { createSelectorHook, useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
// import logger from 'redux-logger';
// eslint-disable-next-line import/no-cycle
import { rootReducer, RootState } from './root-reducer';

export const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./root-reducer', async () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    // eslint-disable-next-line import/no-cycle
    const { rootReducer: newRootReducer } = await import('./root-reducer');
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector = createSelectorHook<RootState>();

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
