import { TableRow } from '@material-ui/core';
import React from 'react';
import { objectTyped } from '@snapshot/shared-utils';
import { DataRow, DataTableContext } from '../../types/datatables';
import { sortFields } from '../../utils/helpers/column-definitions';
import { DataTableCell } from './data-table-cell';

interface DataTableRowProps {
  row: DataRow;
  context: DataTableContext;
  summary?: boolean;
  actions?: boolean;
}

export const DataTableRow: React.FunctionComponent<DataTableRowProps> = ({
  row,
  context,
  summary,
  actions = false,
}) => (
  <TableRow>
    {summary ? (
      <DataTableCell row={row} field="name" context={context} summary={summary} value="Totals" />
    ) : null}
    {sortFields(objectTyped.keys(row)).map((key) => (
      <DataTableCell
        key={key}
        row={row}
        field={key}
        context={context}
        summary={summary}
        value={row[key]}
      />
    ))}
    {actions ? (
      <DataTableCell
        key="actions"
        row={row}
        field="actions"
        context={context}
        summary={summary}
        value=""
      />
    ) : null}
  </TableRow>
);
