import React from 'react';
// eslint-disable-next-line import/extensions
import stringify from 'csv-stringify/lib/browser/index.js';
import moment from 'moment';
import { Button, PropTypes } from '@material-ui/core';
import { objectTyped } from '@snapshot/shared-utils';
import {
  getColumnDefinition,
  getHeaderDefinition,
  sortFields,
} from '../../utils/helpers/column-definitions';
import { DataRow, DataTableContext } from '../../types/datatables';

interface DataExportProps {
  data?: DataRow[];
  context: DataTableContext;
  color: PropTypes.Color | undefined;
}

export const DataExport: React.FunctionComponent<DataExportProps> = ({ data, context, color }) => {
  if (!data) {
    return null;
  }
  const exportCSV = async () => {
    const mappedColumns = sortFields(objectTyped.keys(data[0]))
      .map((fieldName) => {
        const columnDefinition = getColumnDefinition(fieldName);
        if (!columnDefinition) {
          return undefined;
        }
        const { exportName } = getHeaderDefinition(columnDefinition, context);
        return {
          key: columnDefinition.key,
          header: exportName,
        };
      })
      .filter((s) => s) as stringify.ColumnOption[];

    stringify(
      data.map((d) => {
        if ('datetime' in d) {
          // eslint-disable-next-line no-param-reassign
          d.datetime = moment.tz(d.datetime, 'UTC').format('YYYY-MM-DD');
        }
        return d;
      }),
      {
        header: true,
        columns: mappedColumns,
      },
      (err, csv) => {
        const blob = new Blob([csv]);

        const downloadUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
    );
  };

  return (
    <Button variant="contained" color={color} size="small" onClick={() => exportCSV()}>
      Export CSV
    </Button>
  );
};
