import {
  Backdrop,
  Box,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useBySiteStatsLazyQuery } from '@snapshot/data-access';
import moment, { Moment } from 'moment-timezone';
import { notEmpty } from '@snapshot/shared-utils';
import { DateRangePicker } from '../date-range-picker';
import { DataExport } from '../data-export/data-export';
import { DataTableContainer } from '../data-table/data-table-container';
import { useAppSelector } from '../../store';
import { DataRow, DataTableContext } from '../../types/datatables';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export interface StatsTableProps {
  moduleName: 'site';
  day?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      backgroundColor: 'rgba(255,255,255,0.5)',
      zIndex: theme.zIndex.drawer + 1,
      color: '#AAA',
      position: 'absolute',
    },
  }),
);

const moduleMap: Record<'site', string> = {
  site: 'Site',
};

export const ByEntityStatsTable: React.FunctionComponent<StatsTableProps> = ({
  moduleName,
  day,
}) => {
  /**
   * Date Setup
   */
  const [startDate, setStartDate] = useState(
    day
      ? moment.tz(day, 'UTC').startOf('day')
      : moment.tz(undefined, 'UTC').subtract(1, 'day').startOf('month'),
  );
  const [endDate, setEndDate] = useState(
    day ? moment.tz(day, 'UTC').endOf('day') : moment.tz(undefined, 'UTC').endOf('day'),
  );

  const classes = useStyles();

  const user = useAppSelector((state) => state.auth.user);

  const variables = {
    startDate: startDate.clone().startOf('day').format(DATE_FORMAT),
    endDate: endDate.clone().endOf('day').format(DATE_FORMAT),
  };

  if (day) {
    variables.startDate = moment.tz(day, 'UTC').startOf('day').format(DATE_FORMAT);
    variables.endDate = moment.tz(day, 'UTC').endOf('day').format(DATE_FORMAT);
  }

  const [getStats, { loading, data, refetch }] = useBySiteStatsLazyQuery();

  useEffect(() => {
    getStats({
      variables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleName, day]);

  const title = `Daily ${moduleMap[moduleName]} Stats`;

  const context: DataTableContext = {
    moduleName,
    scene: 'stats',
    resolution: 'day',
    user,
  };

  const statResults = data?.bySiteStats?.filter(notEmpty) || [];

  const totalsResults = data?.bySiteStatsTotals?.filter(notEmpty) || [];

  const handleSubmitDate = (start?: Moment, end?: Moment): void => {
    getStats?.({
      variables: {
        ...variables,
        startDate: start?.format(DATE_FORMAT) || startDate.format(DATE_FORMAT),
        endDate: end?.format(DATE_FORMAT) || endDate.format(DATE_FORMAT),
      },
    });
  };

  const showTable = !!statResults?.length;

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12}>
        <Box display="flex" flexWrap="wrap" alignItems="end">
          <Box flexGrow={1}>
            <h1>{title}</h1>
          </Box>
          <Box className="my-auto mr-sm-5">
            <DataExport data={(statResults as DataRow[]) || []} context={context} color="primary" />
          </Box>
          <Box className="ml-md-initial ml-auto">
            <DateRangePicker
              onClickGo={handleSubmitDate}
              onSelectOption={handleSubmitDate}
              startDate={{
                value: startDate,
                setValue: setStartDate,
              }}
              endDate={{
                value: endDate,
                setValue: setEndDate,
              }}
              color="primary"
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {showTable ? (
          <DataTableContainer
            data={(statResults as DataRow[]) || []}
            context={context}
            totals={totalsResults || []}
          />
        ) : null}

        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <Grid className="text-right" item xs={12}>
        <small>* As reported by bidders, subject to change</small>
      </Grid>
    </Grid>
  );
};
