import { createMuiTheme } from '@material-ui/core';
/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../../assets/scss/core/_variables-mui.scss';

export const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: vars.primaryTheme.rgba,
      contrastText: vars.primaryContrastText.rgba,
    },
    grey: {
      300: vars.inheritDefault1.rgba,
      A100: vars.inheritDefault2.rgba,
    },
    secondary: {
      main: vars.secondaryTheme.rgba,
      light: vars.secondaryLight.rgba,
      dark: vars.secondaryDark.rgba,
    },
    error: {
      main: vars.red.rgba,
    },
    success: {
      main: vars.green.rgba,
    },
    warning: {
      main: vars.orange.rgba,
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 0.5,
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.primaryTheme.rgba,
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: vars.primaryTheme.rgba,
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '35px',
      lineHeight: '40px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '29px',
      lineHeight: '32px',
    },
    h3: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '25px',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '14px',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '14px',
    },
    button: {
      fontSize: '14px',
    },
    caption: {
      fontSize: '12px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
});
