import React, { useMemo, useState } from 'react';
import { useByProviderStatsLazyQuery } from '@snapshot/data-access';
import { Moment } from 'moment-timezone';
import { useAppSelector } from '../../store';
import { StatsTable } from '../stats-table';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StatsTableProps {}

export const ByProviderStats: React.FunctionComponent<StatsTableProps> = () => {
  const user = useAppSelector((state) => state.auth.user);

  const [getStats, { loading, data }] = useByProviderStatsLazyQuery();

  const title = 'Bidder Stats';

  const fetchStats = useMemo(
    () => (
      { startDate, endDate }: { startDate: Moment; endDate: Moment },
      publisherId?: number | null,
    ): void => {
      const variables = {
        startDate: startDate.clone().startOf('day').format(DATE_FORMAT),
        endDate: endDate.clone().endOf('day').format(DATE_FORMAT),
        publisherId,
      };
      getStats?.({
        variables,
      });
    },
    [getStats],
  );

  return (
    <>
      <StatsTable
        title={title}
        rows={data?.byProviderStats}
        totals={data?.byProviderStatsTotals}
        loading={loading}
        user={user}
        fetchStats={fetchStats}
        publisherFilter
      />
    </>
  );
};
