/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Container, Grid, Card, CardContent, createStyles, makeStyles } from '@material-ui/core';
import { Footer } from './footer';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      gap: theme.spacing(5),
    },
    image: {
      display: 'none',
      [theme.breakpoints.up('xl')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    action: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    text: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4.fontSize,
    },
  }),
);

export const AuthLayout: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.image}>
          <img alt="Datablocks Logo" src="/public/login-logo.png" />
        </div>
        <div className={classes.action}>
          <p className={classes.text}>Welcome back to the Datablocks Exchange!</p>
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
