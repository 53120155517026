import React, { useMemo, useState } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';

import { DatePicker, DatePickerProps } from '../../date-range-picker/date-picker/date-picker';

interface ExtraFormFields {
  name: string;
  label: string;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: (value: Moment) => void;
}

type FormDateFieldProps = ExtraFormFields & Partial<DatePickerProps>;

export const FormDateField: React.FunctionComponent<FormDateFieldProps> = ({
  name,
  label,
  hasError,
  errorMessage,
  format,
  views,
  onChange,
}) => {
  const [field, meta, { setValue }] = useField({ name, type: 'string' });

  const handleDateChange = (date: Moment) => {
    setValue(date.format(format));
  };

  const value = useMemo(() => moment(field.value, format), [field.value, format]);

  return (
    <FormControl className="w-100 mb-3">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          views={views}
          label={label}
          // value={moment(field.value)}
          setValue={(v) => {
            handleDateChange(v);
            onChange?.(v);
          }}
          value={value}
          hasError={hasError}
        />
        {meta.touched ? <FormHelperText error={hasError}>{errorMessage}</FormHelperText> : null}
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};
