export const bidderMap: Record<string, string> = {
  adaptmx: 'Adapt MX',
  advangelists: 'Advangelists',
  aol: 'Verizon Display',
  appnexus: 'AppNexus',
  brightcom: 'Brightcom',
  bucksense: 'Bucksense',
  cedato: 'Cedato',
  conversant: 'Conversant',
  districtm: 'DistrictM',
  google: 'Google',
  gumgum: 'GumGum',
  infolinks: 'Infolinks',
  ix: 'Index Exchange',
  krushmedia: 'Krush Media',
  oftmedia: '152 Media',
  openx: 'OpenX',
  pixfuture: 'PixFuture',
  pubmatic: 'PubMatic',
  rubicon: 'Rubicon',
  sharethrough: 'Sharethrough',
  smartadserver: 'Smart Adserver (Fuel Digital)',
  sonobi: 'Sonobi',
  sovrn: 'Sovrn',
  synacormedia: 'Synacor Media',
  triplelift: 'TripleLift',
  unruly: 'Unruly',
  verizonmedia: 'Verizon Media',
  verizonnative: 'Verizon Native',
};
