import { TablePagination } from '@material-ui/core';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { getPagination, setPage, setPerPage } from '../../store/data-table/data-table-slice';

const rowsPerPageOptions = [10, 25, 50, 100];

interface DataTablePaginationProps {
  itemCount: number;
}

export const DataTablePagination: React.FunctionComponent<DataTablePaginationProps> = ({
  itemCount,
}) => {
  const paginationState = useAppSelector(getPagination);
  const dispatch = useAppDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={itemCount}
      rowsPerPage={paginationState.perPage}
      page={paginationState.currentPage}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};
