import React from 'react';
import { Box, Button, ButtonProps, CircularProgress, InputProps } from '@material-ui/core';

/* eslint-disable-next-line */
export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  text: string;
}

export const LoadingButton = ({
  loading = false,
  text,
  ...buttonProps
}: LoadingButtonProps) => (
  <Button
    variant="contained"
    className="my-2 text-center"
    type="submit"
    disabled={loading}
    {...buttonProps}
  >
    {text}
    {loading ? (
      <CircularProgress color="primary" style={{ position: 'absolute' }} size={24} />
    ) : null}
  </Button>
);
