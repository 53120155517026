/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Box, Button, IconButton, Link, TableCellProps } from '@material-ui/core';
import moment from 'moment-timezone';
import { Link as RouterLink } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import { AllKeys, Idx } from '../../types/helpers';
import { DataRow, DataTableContext, Grouping } from '../../types/datatables';
import { numberFormatter, currencyFormatter } from './formatters';

export type CellValue =
  | string
  | number
  | boolean
  | Date
  | null
  | undefined
  | Record<string, unknown>;

export type ColumnDefinitionContext<K extends AllKeys<DataRow>> = DataTableContext & {
  value: Idx<DataRow, K>;
  row: DataRow;
  summary?: boolean;
};

export interface ColumnDefinition<
  Key extends AllKeys<DataRow>,
  Ctx = ColumnDefinitionContext<Key>
> {
  key: Key;
  headerName: string | ((dCtx: DataTableContext) => string);
  valueFormatter?: (ctx: Ctx) => CellValue;
  hide?: boolean | ((dCtx: DataTableContext) => boolean);
  renderCell?: (ctx: Ctx) => JSX.Element | string;
  grouping?: Grouping;
  sortIndex?: number;
  tooltip?: string;
  sortField?: AllKeys<DataRow>;
  cellProps?: Partial<TableCellProps> | ((ctx: Ctx) => Partial<TableCellProps>);
  headerProps?: Partial<TableCellProps> | ((dCtx: DataTableContext) => Partial<TableCellProps>);
}

const dbTooltip = 'Includes Datablocks Exchange bidder revenue.';
const clientTooltip = "Includes Client's direct bidder revenue";
const googleTooltip = 'Includes Google AdX, AdSense, and OpenBidding revenue.';

type ColumnDefinitionArray = { [K in AllKeys<DataRow>]: ColumnDefinition<K> }[AllKeys<DataRow>][];

export const columnDefinitions: ColumnDefinitionArray = [
  {
    key: 'id',
    hide: true,
    headerName: 'ID',
    sortIndex: 0,
  },
  {
    key: 'datetime',
    headerName: 'Date',
    valueFormatter: (context) => moment.tz(context.value?.toString(), 'UTC').format('MMM Do'),
    renderCell: (context) => {
      if (context.summary) {
        return 'Totals:';
      }
      if (context.moduleName === 'site') {
        return moment.tz(context.value?.toString(), 'UTC').format('MMM Do');
      }
      return (
        <Link
          component={RouterLink}
          to={`/sites?day=${moment.tz(context.value, 'UTC').format('YYYY-MM-DD')}`}
        >
          {moment.tz(context.value?.toString(), 'UTC').format('MMM Do')}
        </Link>
      );
    },
    sortIndex: 4,
    cellProps: {
      style: {
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    key: 'site',
    headerName: 'Site',
    renderCell: (context) => {
      if (context.summary) {
        return 'Totals:';
      }
      return (
        <Link component={RouterLink} to={`/sites/${context.value?.id}/daily`}>
          {context.value?.name}
        </Link>
      );
    },
    sortIndex: 5,
  },
  {
    key: 'name',
    headerName: 'Name',
    renderCell: (context) => {
      if (context.summary) {
        return 'Totals:';
      }
      if ('id' in context.row) {
        if (context.moduleName === 'publisher') {
          return (
            <Link
              component={RouterLink}
              to={`/manage/${context.moduleName}s/${context.row.id}/sites`}
            >
              {context.value}
            </Link>
          );
        }
        if (context.moduleName === 'site') {
          return (
            <Link
              component={RouterLink}
              to={`/manage/${context.moduleName}s/${context.row.id}/connections`}
            >
              {context.value}
            </Link>
          );
        }
        return (
          <Link component={RouterLink} to={`/${context.moduleName}s/${context.row.id}/daily`}>
            {context.value}
          </Link>
        );
      }
      return '';
    },
    sortIndex: 5,
  },
  {
    key: 'feedProvider',
    headerName: 'Bidder',
    sortIndex: 10,
  },
  {
    key: 'dbImpressions',
    headerName: 'Datablocks Bidders',
    valueFormatter: numberFormatter(),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 10,
    tooltip: dbTooltip,
    grouping: 'Impressions',
  },
  {
    key: 'cliImpressions',
    hide: (ctx) =>
      !ctx.user?.superadmin && !!ctx.user?.permissions?.every((p) => p.agencyId === 2522351),
    headerName: 'Client Bidders',
    valueFormatter: numberFormatter(),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 10,
    tooltip: clientTooltip,
    grouping: 'Impressions',
  },
  {
    key: 'googleImpressions',
    headerName: 'Google',
    valueFormatter: numberFormatter(),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 20,
    tooltip: googleTooltip,
    grouping: 'Impressions',
  },
  {
    key: 'totalImpressions',
    headerName: 'Total',
    valueFormatter: numberFormatter(),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 30,
    grouping: 'Impressions',
  },
  {
    key: 'dbEstimatedCpm',
    headerName: 'Datablocks Bidders',
    valueFormatter: currencyFormatter(4),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    grouping: 'Estimated CPM',
    tooltip: dbTooltip,
    sortIndex: 40,
  },
  {
    key: 'cliEstimatedCpm',
    hide: (ctx) =>
      !ctx.user?.superadmin && !!ctx.user?.permissions?.every((p) => p.agencyId === 2522351),
    headerName: 'Client Bidders',
    valueFormatter: currencyFormatter(4),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    grouping: 'Estimated CPM',
    tooltip: clientTooltip,
    sortIndex: 40,
  },
  {
    key: 'googleEstimatedCpm',
    headerName: 'Google',
    valueFormatter: currencyFormatter(4),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    grouping: 'Estimated CPM',
    tooltip: googleTooltip,
    sortIndex: 50,
  },
  {
    key: 'totalEstimatedCpm',
    headerName: 'Total',
    valueFormatter: currencyFormatter(4),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    grouping: 'Estimated CPM',
    sortIndex: 60,
  },
  {
    key: 'dbEstimatedEarning',
    headerName: 'Datablocks Bidders',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 70,
    tooltip: dbTooltip,
    grouping: 'Estimated Revenue',
  },
  {
    key: 'cliEstimatedEarning',
    hide: (ctx) =>
      !ctx.user?.superadmin && !!ctx.user?.permissions?.every((p) => p.agencyId === 2522351),
    headerName: 'Client Bidders',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 70,
    tooltip: clientTooltip,
    grouping: 'Estimated Revenue',
  },
  {
    key: 'googleEstimatedEarning',
    headerName: 'Google',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 80,
    tooltip: googleTooltip,
    grouping: 'Estimated Revenue',
  },
  {
    key: 'totalEstimatedEarning',
    headerName: 'Total',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 90,
    grouping: 'Estimated Revenue',
  },
  {
    key: 'dbValidatedEarning',
    headerName: 'Datablocks Bidders',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 100,
    tooltip: dbTooltip,
    grouping: 'Validated Revenue',
  },
  {
    key: 'cliValidatedEarning',
    hide: (ctx) =>
      !ctx.user?.superadmin && !!ctx.user?.permissions?.every((p) => p.agencyId === 2522351),
    headerName: 'Client Bidders',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 100,
    tooltip: clientTooltip,
    grouping: 'Validated Revenue',
  },
  {
    key: 'googleValidatedEarning',
    headerName: 'Google',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 110,
    tooltip: googleTooltip,
    grouping: 'Validated Revenue',
  },
  {
    key: 'totalValidatedEarning',
    headerName: 'Total',
    valueFormatter: currencyFormatter(2),
    headerProps: {
      align: 'right',
    },
    cellProps: {
      align: 'right',
    },
    sortIndex: 120,
    grouping: 'Validated Revenue',
  },
  {
    key: 'actions',
    headerName: 'Actions',
    renderCell: (context) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { id } = context.row as any;
      const connectionsButton = (
        <IconButton size="small" component={RouterLink} to={`/manage/sites/${id}/connections`}>
          <LinkIcon fontSize="small" />
        </IconButton>
      );

      const editButton = (
        <IconButton size="small" component={RouterLink} to={`/manage/sites/${id}/edit`}>
          <EditIcon fontSize="small" />
        </IconButton>
      );

      return (
        <Box display="flex" justifyContent="space-between">
          {context.moduleName === 'site' ? <Box>{connectionsButton}</Box> : null}
          <Box>{editButton}</Box>
        </Box>
      );
    },
  },
];

export const getColumnDefinition = <K extends AllKeys<DataRow>>(key: K) => {
  const definition = columnDefinitions.find((c) => c.key === key);
  if (!definition) {
    return null;
  }
  return definition as ColumnDefinition<K>;
};

export const sortFields = <Key extends AllKeys<DataRow>>(fields: Key[]): Key[] =>
  fields.sort((a, b) => {
    const colDefA = getColumnDefinition(a);
    const colDefB = getColumnDefinition(b);

    const sortA = colDefA?.sortIndex === undefined ? Infinity : colDefA?.sortIndex;
    const sortB = colDefB?.sortIndex === undefined ? Infinity : colDefB?.sortIndex;

    if (sortA > sortB) {
      return 1;
    }
    if (sortB > sortA) {
      return -1;
    }
    return 0;
  });

function getValue<
  K extends AllKeys<DataRow>,
  ValueType = unknown,
  C extends ColumnDefinitionContext<K> = ColumnDefinitionContext<K>
>(value: ValueType | ((ctx: C) => ValueType), context: C): ValueType {
  if (value instanceof Function) {
    return value(context);
  }
  return value;
}

function getHeaderValue<ValueType = unknown, C extends DataTableContext = DataTableContext>(
  value: ValueType | ((ctx: C) => ValueType),
  context: C,
): ValueType {
  if (value instanceof Function) {
    return value(context);
  }
  return value;
}

export const getHeaderDefinition = <K extends AllKeys<DataRow>>(
  columnDefinition: ColumnDefinition<K>,
  context: DataTableContext,
) => {
  const headerName = getHeaderValue(columnDefinition.headerName, context);
  let exportName = headerName;
  if (columnDefinition.grouping) {
    exportName = `${headerName} ${columnDefinition.grouping}`;
  }
  const hide = getHeaderValue(columnDefinition.hide, context);
  return {
    headerName,
    exportName,
    hide,
    headerProps: getHeaderValue(columnDefinition.headerProps, context),
  };
};

export const getMappedDefinition = <K extends AllKeys<DataRow>>(
  columnDefinition: ColumnDefinition<K>,
  context: ColumnDefinitionContext<K>,
) => {
  const { headerName, exportName, hide } = getHeaderDefinition(columnDefinition, context);

  return {
    ...columnDefinition,
    headerName,
    exportName,
    hide,
    valueFormatter: getValue(columnDefinition.valueFormatter, context),
    renderCell: getValue(columnDefinition.renderCell, context),
    sortField: columnDefinition.sortField || columnDefinition.key,
    cellProps: getValue(columnDefinition.cellProps, context),
    headerProps: getValue(columnDefinition.headerProps, context),
  };
};

interface GroupingColumn {
  grouping: Grouping | null;
  colSpan: number;
}

export const getGroupings = (keys: AllKeys<DataRow>[]) => {
  const groupings: GroupingColumn[] = [];
  keys.forEach((key) => {
    const colDef = getColumnDefinition(key);
    if (!colDef) {
      return;
    }
    const grouping = colDef.grouping || null;
    const lastGrouping = groupings[groupings.length - 1];
    if (lastGrouping?.grouping === grouping) {
      lastGrouping.colSpan += 1;
    } else {
      groupings.push({
        grouping,
        colSpan: 1,
      });
    }
  });
  return groupings;
};
