import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    unit: yup.number().required(),
    delDomain: yup.string().required(),
    siteId: yup.number().required(),
  }),
  reporting: yup.object({
    consumer_key: yup.string().required(),
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
    siteId: yup.number().required(),
    delDomain: yup.string().required(),
  }),
};

export const openx: BidderAdapter = {
  name: 'openx',
  requirements,
  getConnectionValues: (bidder) => {
    const formattedMetadata = JSON.parse(bidder.metadata);
    return {
      delDomain: formattedMetadata.delDomain,
      siteId: formattedMetadata.siteId,
    };
  },
  overallRequirements: ['delDomain', 'siteId'],
};
