import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Box, Checkbox, TextField } from '@material-ui/core';
import type { CBidder } from '../connections-page';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface BidderSelectorProps {
  bidders: CBidder[];
  selectedBidders: CBidder[];
  updateSelectedBidders: (selectedBidders: CBidder[], selectedBidder?: CBidder) => void;
}

export const BidderSelector: React.FunctionComponent<BidderSelectorProps> = ({
  bidders,
  selectedBidders,
  updateSelectedBidders,
}) => {
  const handleChange = (bidderList: CBidder[], selectedBidder?: CBidder) => {
    updateSelectedBidders(bidderList, selectedBidder);
  };
  return (
    <Autocomplete
      multiple
      options={bidders}
      value={selectedBidders}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.provider} | ${option.agencyName}`}
      onChange={(a, list, c, d) => handleChange(list, d?.option)}
      renderOption={(option, state) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={state.selected}
          />
          <Box display="flex">
            <Box>{option.provider} |</Box>
            <Box>&nbsp;{option.agencyName}</Box>
          </Box>
        </>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Bidders" placeholder="Provider | Agency" />
      )}
    />
  );
};
