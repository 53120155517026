import { Paper, Table, TableContainer } from '@material-ui/core';
import React, { useEffect } from 'react';
import { objectTyped } from '@snapshot/shared-utils';
import { useAppDispatch, useAppSelector } from '../../store';
import { DataTableContext, DataRow } from '../../types/datatables';
import { stableSort } from '../../utils/helpers/stable-sort';
import { DataTableBody } from './data-table-body';
import { DataTableHeaders } from './data-table-headers';
import { DataTablePagination } from './data-table-pagination';
import { getPagination, getSort, setPage, setSort } from '../../store/data-table/data-table-slice';

interface DataTableContainerProps {
  data: DataRow[];
  totals?: DataRow[];
  context: DataTableContext;
  actions?: boolean;
}

export const DataTableContainer: React.FunctionComponent<DataTableContainerProps> = ({
  data,
  context,
  totals,
  actions = false,
}) => {
  const headers = objectTyped.keys(data[0] || {});
  const dispatch = useAppDispatch();
  const { field, direction } = useAppSelector(getSort);
  const pagination = useAppSelector(getPagination);

  useEffect(() => {
    if ('datetime' in data[0]) {
      dispatch(
        setSort({
          field: 'datetime',
          direction: 'asc',
        }),
      );
    } else if ('totalValidatedEarning' in data[0]) {
      dispatch(
        setSort({
          field: 'totalValidatedEarning',
          direction: 'desc',
        }),
      );
    } else if ('totalEstimatedEarning' in data[0]) {
      dispatch(
        setSort({
          field: 'totalEstimatedEarning',
          direction: 'desc',
        }),
      );
    }
    dispatch(setPage(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.resolution, context.moduleName]);

  const sortedData = stableSort(data, direction, field).slice(
    pagination.currentPage * pagination.perPage,
    pagination.currentPage * pagination.perPage + pagination.perPage,
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          <DataTableHeaders headers={headers} context={context} actions={actions} />
          <DataTableBody context={context} data={sortedData} totals={totals} actions={actions} />
        </Table>
      </TableContainer>
      <DataTablePagination itemCount={data.length} />
    </>
  );
};
