import React from 'react';
import { Formik, FormikConfig, Form, FieldArray } from 'formik';
import { useCreatePlacementMutation, useSitesQuery } from '@snapshot/data-access';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Paper } from '@material-ui/core';
import { FormTextField } from './form-text-field/form-text-field';
import { LoadingButton } from '../loading-button/loading-button';
import { FormLayout } from '../layout/form-layout';
import { FormSelectField } from './form-select-field/form-select-field';
import { FormSizesField } from './form-sizes-field/form-sizes-field';
import { FormCheckboxField } from './form-checkbox-field/form-checkbox-field';
import { FormDimensionsField } from './form-dimensions-field/form-dimensions-field';

export const CreatePlacement: React.FunctionComponent = () => {
  // Get the agencies
  const { data: sitesQueryData, loading: sitesLoading } = useSitesQuery();

  const [createPlacement, { loading: createPlacementLoading }] = useCreatePlacementMutation();

  const sites = sitesQueryData?.sites || [];

  type Size = [number, number];

  type SizeMapping = {
    sizes: ('fluid' | Size)[];
    viewport: Size;
  };

  const initialValues: {
    siteId: number;
    code: string;
    googleCode: string;
    networkCode: number | null;
    metadata: Record<string, unknown>;
    details: {
      mediaTypes: {
        banner: {
          sizeMappings: SizeMapping[];
        };
      };
    };
  } = {
    siteId: 0,
    code: '',
    googleCode: '',
    networkCode: 0,
    metadata: {
      dls: '',
      no_psa: false,
      dynamic: 0,
      refresh: 0,
      widget_type: '',
      widget_options: '',
      only_refresh_remnant: 1,
    },
    details: {
      mediaTypes: {
        banner: {
          sizeMappings: [],
        },
      },
    },
  };

  const validationSchema = yup.object().shape({
    siteId: yup
      .number()
      .required('Site is required')
      .oneOf(
        sites.map((site) => site.id),
        'Site is required',
      ),
    code: yup.string().required('Code is required'),
    googleCode: yup.string().required('Google Code is required'),
    networkCode: yup.number().required('Network Code is required'),
  });

  const onSubmit = async (placementData: typeof initialValues) => {
    const metadata = JSON.stringify(placementData.metadata);
    const details = JSON.stringify(placementData.details);
    await createPlacement({
      variables: {
        ...placementData,
        metadata,
        details,
        networkCode: placementData.networkCode?.toString() || '',
        divId: placementData.code,
      },
    });
  };

  return (
    <FormLayout title="Create Placement">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(props) => {
          const { values } = props;

          return (
            <Form>
              <FormSelectField
                name="siteId"
                label="Site"
                type="number"
                options={sites.map((site) => ({
                  label: site.name,
                  value: site.id,
                }))}
              />

              <FormTextField name="code" label="Code" type="text" />

              <FormTextField name="googleCode" label="Google Code" type="text" />

              <FormTextField name="networkCode" label="Network Code" type="number" />

              {/* <FormTextField name="details" label="Details" type="text" /> */}
              <Card>
                <FieldArray
                  name="details.mediaTypes.banner.sizeMappings"
                  render={(arrayHelpers) => (
                    <CardContent>
                      <CardHeader
                        title={
                          <Box display="flex" align-items="center">
                            <Box align-items="center" alignSelf="center" className="mr-2">
                              Size Mappings
                            </Box>
                            <Button
                              color="secondary"
                              variant="outlined"
                              type="button"
                              size="small"
                              onClick={() => {
                                arrayHelpers.push({
                                  sizes: [],
                                  viewport: [0, 0],
                                });
                              }}
                            >
                              Add Size Mapping
                            </Button>
                          </Box>
                        }
                      />
                      {values.details.mediaTypes.banner.sizeMappings.map((sizeMapping, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Card variant="outlined" key={index}>
                          <CardContent>
                            <FieldArray
                              name={`details.mediaTypes.banner.sizeMappings[${index}].sizes`}
                              render={(arrayHelpers) => (
                                <>
                                  <Box display="flex" alignItems="center">
                                    <FormDimensionsField
                                      name={`details.mediaTypes.banner.sizeMappings[${index}].viewport`}
                                      label="Viewport"
                                      className="mr-2"
                                    />
                                  </Box>
                                  <FormSizesField
                                    name={`details.mediaTypes.banner.sizeMappings[${index}].sizes`}
                                    label="Sizes"
                                  />
                                </>
                              )}
                            />
                          </CardContent>
                          <CardActions>
                            <Button
                              color="secondary"
                              variant="outlined"
                              type="button"
                              size="small"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              Remove Size Mapping
                            </Button>
                          </CardActions>
                        </Card>
                      ))}
                    </CardContent>
                  )}
                />
              </Card>
              <br />
              <Card>
                <CardContent>
                  <CardHeader title="Metadata" />
                  <FormTextField name="metadata.dls" label="DLS" type="text" />
                  <FormCheckboxField name="metadata.no_psa" label="No PSA" />
                  <FormTextField name="metadata.dynamic" label="Dynamic" type="number" />
                  <FormTextField name="metadata.refresh" label="Refresh" type="number" />
                  <FormTextField name="metadata.widget_type" label="Widget Type" type="text" />
                  <FormTextField
                    name="metadata.widget_options"
                    label="Widget Options"
                    type="text"
                  />
                  <FormTextField
                    name="metadata.only_refresh_remnant"
                    label="Only Refresh Remnant"
                    type="number"
                  />
                </CardContent>
              </Card>

              <LoadingButton
                loading={createPlacementLoading || sitesLoading}
                type="submit"
                text="Create Placement"
              />
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};
