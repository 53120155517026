import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateObject } from '../../utils/helpers/update-object';

export interface ThemeState {
  sidebarShadow: boolean;
  sidebarFixed: boolean;
  sidebarToggleMobile: boolean;
  sidebarFooter: boolean;
  sidebarUserbox: boolean;
  sidebarToggle: boolean;
  sidebarHover: boolean;
  // Header
  headerFixed: boolean;
  headerShadow: boolean;
  headerSearchHover: boolean;
  // Main content
  contentBackground: string;
  themeConfiguratorToggle: boolean;
  // Footer
  footerFixed: boolean;
  footerShadow: boolean;
  // Page title
  pageTitleStyle: string;
  pageTitleBackground: string;
  pageTitleShadow: boolean;
  pageTitleBreadcrumb: boolean;
  pageTitleIconBox: boolean;
  pageTitleDescription: boolean;
}

const initialState: ThemeState = {
  sidebarShadow: false,
  sidebarFixed: true,
  sidebarToggleMobile: false,
  sidebarFooter: true,
  sidebarUserbox: true,
  sidebarToggle: false,
  sidebarHover: false,
  // Header
  headerFixed: true,
  headerShadow: true,
  headerSearchHover: false,
  // Main content
  contentBackground: '',
  themeConfiguratorToggle: false,
  // Footer
  footerFixed: false,
  footerShadow: false,
  // Page title
  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleBreadcrumb: false,
  pageTitleIconBox: true,
  pageTitleDescription: true,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setSidebarShadow: (state, action: PayloadAction<Pick<ThemeState, 'sidebarShadow'>>) =>
      updateObject(state, action.payload),
    setSidebarFixed: (state, action: PayloadAction<Pick<ThemeState, 'sidebarFixed'>>) =>
      updateObject(state, action.payload),
    setSidebarToggleMobile: (
      state,
      action: PayloadAction<Pick<ThemeState, 'sidebarToggleMobile'>>,
    ) => updateObject(state, action.payload),
    setSidebarFooter: (state, action: PayloadAction<Pick<ThemeState, 'sidebarFooter'>>) =>
      updateObject(state, action.payload),
    setSidebarToggle: (state, action: PayloadAction<Pick<ThemeState, 'sidebarToggle'>>) =>
      updateObject(state, action.payload),
    setSidebarHover: (state, action: PayloadAction<Pick<ThemeState, 'sidebarHover'>>) =>
      updateObject(state, action.payload),
    setSidebarUserbox: (state, action: PayloadAction<Pick<ThemeState, 'sidebarUserbox'>>) =>
      updateObject(state, action.payload),
    setHeaderFixed: (state, action: PayloadAction<Pick<ThemeState, 'headerFixed'>>) =>
      updateObject(state, action.payload),
    setHeaderShadow: (state, action: PayloadAction<Pick<ThemeState, 'headerShadow'>>) =>
      updateObject(state, action.payload),
    setHeaderSearchHover: (state, action: PayloadAction<Pick<ThemeState, 'headerSearchHover'>>) =>
      updateObject(state, action.payload),
    setContentBackground: (state, action: PayloadAction<Pick<ThemeState, 'contentBackground'>>) =>
      updateObject(state, action.payload),
    setThemeConfiguratorToggle: (
      state,
      action: PayloadAction<Pick<ThemeState, 'themeConfiguratorToggle'>>,
    ) => updateObject(state, action.payload),
    setFooterFixed: (state, action: PayloadAction<Pick<ThemeState, 'footerFixed'>>) =>
      updateObject(state, action.payload),
    setFooterShadow: (state, action: PayloadAction<Pick<ThemeState, 'footerShadow'>>) =>
      updateObject(state, action.payload),
    setPageTitleStyle: (state, action: PayloadAction<Pick<ThemeState, 'pageTitleStyle'>>) =>
      updateObject(state, action.payload),
    setPageTitleBackground: (
      state,
      action: PayloadAction<Pick<ThemeState, 'pageTitleBackground'>>,
    ) => updateObject(state, action.payload),
    setPageTitleShadow: (state, action: PayloadAction<Pick<ThemeState, 'pageTitleShadow'>>) =>
      updateObject(state, action.payload),
    setPageTitleBreadcrumb: (
      state,
      action: PayloadAction<Pick<ThemeState, 'pageTitleBreadcrumb'>>,
    ) => updateObject(state, action.payload),
    setPageTitleIconBox: (state, action: PayloadAction<Pick<ThemeState, 'pageTitleIconBox'>>) =>
      updateObject(state, action.payload),
    setPageTitleDescription: (
      state,
      action: PayloadAction<Pick<ThemeState, 'pageTitleDescription'>>,
    ) => updateObject(state, action.payload),
  },
});

export const {
  setSidebarShadow,
  setSidebarFixed,
  setSidebarToggleMobile,
  setSidebarFooter,
  setSidebarToggle,
  setSidebarHover,
  setSidebarUserbox,
  setHeaderFixed,
  setHeaderShadow,
  setHeaderSearchHover,
  setContentBackground,
  setThemeConfiguratorToggle,
  setFooterFixed,
  setFooterShadow,
  setPageTitleStyle,
  setPageTitleBackground,
  setPageTitleShadow,
  setPageTitleBreadcrumb,
  setPageTitleIconBox,
  setPageTitleDescription,
} = themeSlice.actions;
export const { reducer } = themeSlice;
