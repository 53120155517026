import type { StoredUser } from './auth.slice';

const AUTH_STORAGE_KEY = 'auth/user';

export const storeUser = (user: StoredUser) => {
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(user));
};

export const getUser = (): StoredUser | null => {
  try {
    const storedItem = localStorage.getItem(AUTH_STORAGE_KEY);
    if (storedItem) {
      return JSON.parse(storedItem);
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const clearUser = () => {
  localStorage.removeItem(AUTH_STORAGE_KEY);
};
