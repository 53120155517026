import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    adSlot: yup.string().required(),
    publisherId: yup.string().required(),
    siteId: yup.string().required(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
    publisherId: yup.string().required(),
  }),
};

export const pubmatic: BidderAdapter = {
  name: 'pubmatic',
  requirements,
  getConnectionValues: (bidder) => {
    const formattedMetadata = JSON.parse(bidder.metadata);
    return {
      publisherId: formattedMetadata.publisherId,
    };
  },
  overallRequirements: ['publisherId', 'siteId'],
};
