/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import TodayTwoToneIcon from '@material-ui/icons/TodayTwoTone';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import LanguageTwoToneIcon from '@material-ui/icons/LanguageTwoTone';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import LanguageIcon from '@material-ui/icons/Language';
import { NavLinkItem } from '../navigation';
import { PersistentDrawerLayout } from '../layouts';
import { StoredUser } from '../../store/authentication';
import projectLogo from '../../assets/images/icon-100.png';
import { Footer } from './footer';
import { HeaderUserbox } from './header/header-user-box';

interface MainLayoutProps {
  user?: StoredUser;
}

const navBarItems = [<HeaderUserbox />];

export const MainLayout: React.FunctionComponent<MainLayoutProps> = ({ children, user }) => {
  const drawerWidth = 200;
  const logo = {
    src: projectLogo,
    alt: 'Datablocks Exchange Home',
  };

  const drawerNavLinkitems: NavLinkItem[] = [
    {
      to: '/stats',
      label: 'Daily Stats',
      icon: <TodayTwoToneIcon />,
    },
    {
      to: '/sites',
      label: 'Website Stats',
      icon: <LanguageTwoToneIcon />,
    },
  ];

  if (user?.superadmin) {
    drawerNavLinkitems.push(
      ...[
        {
          to: '/bidder-stats',
          label: 'Bidder Stats',
          icon: <RssFeedIcon />,
        },
        {
          to: '/manage/sites',
          label: 'Sites',
          icon: <LanguageIcon />,
        },
        // {
        //   to: '/manage/connections',
        //   label: 'Connections',
        //   icon: <InsertLinkIcon />,
        // },
        // {
        //   to: '/manage/publishers',
        //   label: 'Publishers',
        //   icon: <RecentActorsIcon />,
        // },
      ],
    );
  }

  return (
    <PersistentDrawerLayout
      logo={logo}
      drawerNavLinkitems={drawerNavLinkitems}
      drawerWidth={drawerWidth}
      title="Datablocks Exchange"
      footer={<Footer />}
      navBarItems={navBarItems}
    >
      {children}
    </PersistentDrawerLayout>
  );
};
