import { createStyles, makeStyles, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { notEmpty } from '@snapshot/shared-utils';
import { DataRow, DataTableContext } from '../../types/datatables';
import {
  getColumnDefinition,
  sortFields,
  getGroupings,
} from '../../utils/helpers/column-definitions';
import { DataTableHeaderCell } from './data-table-header-cell';

export interface DataTableHeaderProps {
  headers: Array<keyof DataRow>;
  context: DataTableContext;
  actions?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderBottom: 'none',
    },
  }),
);

export const DataTableHeaders: React.FunctionComponent<DataTableHeaderProps> = ({
  headers,
  context,
  actions = false,
}) => {
  const mappedHeaders = sortFields(headers)
    .map((field) => getColumnDefinition(field))
    .filter(notEmpty)
    .filter((c) => {
      if (!c) {
        throw new Error();
      }
      if (typeof c.hide === 'function') {
        return !c.hide(context);
      }
      return !c.hide;
    });

  const groupings = getGroupings(mappedHeaders.map((c) => c.key));

  const classes = useStyles();

  return (
    <TableHead>
      {groupings.length > 1 && (
        <TableRow>
          {groupings.map((grouping, i) => (
            <TableCell
              className={clsx(classes.tableCell, 'table-grouping-cell')}
              // eslint-disable-next-line react/no-array-index-key
              key={`grouping-${grouping}-${i}`}
              colSpan={grouping.colSpan}
              align="center"
            >
              {/* add star for validated stats */}
              {grouping.grouping} {grouping.grouping === 'Validated Revenue' ? ' *' : ''}
            </TableCell>
          ))}
          {actions ? (
            <TableCell
              style={{
                width: 150,
              }}
              size="small"
            >
              Actions
            </TableCell>
          ) : null}
        </TableRow>
      )}
      <TableRow>
        {mappedHeaders.map(
          (header) =>
            header && (
              <DataTableHeaderCell key={header.key} fieldName={header.key} context={context} />
            ),
        )}
        {actions ? (
          <DataTableHeaderCell key="actions" fieldName="actions" context={context} />
        ) : null}
      </TableRow>
    </TableHead>
  );
};
