import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    site_id: yup.string().required(),
    tag_id: yup.string().required(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
  }),
};

export const conversant: BidderAdapter = {
  name: 'conversant',
  requirements,
  overallRequirements: ['site_id'],
};
