import React from 'react';
import { usePublishersQuery, useSitesQuery } from '@snapshot/data-access';
import { Backdrop, CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import { useAppSelector } from '../../store';
import { DataTableContext } from '../../types/datatables';
import { DataTableContainer } from '../../components/data-table/data-table-container';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    zIndex: theme.zIndex.drawer + 1,
    color: '#AAA',
    position: 'absolute',
  },
}));

export const SitesPage = () => {
  const { data, loading } = useSitesQuery();
  const { user } = useAppSelector(({ auth }) => auth);

  const classes = useStyles();

  const sites = data?.sites || [];

  const dataTableContext: DataTableContext = {
    moduleName: 'site',
    scene: 'manage',
    user,
  };

  const showTable = sites.length;

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Sites</h1>
      </Grid>
      <Grid item xs={12}>
        {showTable ? <DataTableContainer actions context={dataTableContext} data={sites} /> : null}
        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
};
