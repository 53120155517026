import { Link, Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import * as yup from 'yup';
import { ApolloError } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import { useResetUserPasswordMutation } from '@snapshot/data-access';
import { FieldMap, FormContainer, passwordField } from '../components/forms';
import { AuthLayout } from '../components/layout/auth';

interface ResetPasswordProps {
  token?: string;
}

type ResetPasswordFormData = {
  password: string;
  passwordConfirmation: string;
};

export const ResetPasswordPage: React.FunctionComponent<ResetPasswordProps> = ({ token }) => {
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>('');
  const [successMessage, setSuccessMessage] = useState<string | JSX.Element>('');
  const [resetUserPassword, { loading }] = useResetUserPasswordMutation();

  if (!token) {
    return <AuthLayout>Something went wrong. Please try the link in your email again.</AuthLayout>;
  }

  const onSubmit = async (values: ResetPasswordFormData) => {
    await resetUserPassword({
      variables: {
        password: values.password,
        token,
      },
    })
      .then((response) => {
        if (response.data?.resetUserPassword) {
          setErrorMessage('');
          setSuccessMessage(
            <span>
              Your password has been reset, please{' '}
              <Link color="primary" href="/login">
                click here
              </Link>{' '}
              to login.
            </span>,
          );
          return true;
        }
        setSuccessMessage('');
        setErrorMessage(
          <span>
            There was an error trying to reset your password. Please{' '}
            <Link color="primary" href="/forgot-password">
              click here
            </Link>{' '}
            to request another password reset email.
          </span>,
        );
        return false;
      })
      .catch((e: ApolloError) => {
        if (e.message?.includes('expired')) {
          setSuccessMessage('');
          setErrorMessage(
            <span>
              Your token has expired. Please{' '}
              <Link color="primary" href="/forgot-password">
                click here
              </Link>{' '}
              to request another password reset email.
            </span>,
          );
        } else {
          setSuccessMessage('');
          setErrorMessage(
            <span>
              There was an error trying to reset your password. Please{' '}
              <Link color="primary" href="/forgot-password">
                click here
              </Link>{' '}
              to request another password reset email.
            </span>,
          );
        }
      });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage('');
    setSuccessMessage('');
  };

  const passwordConfirmationField = passwordField();

  const fields: FieldMap<ResetPasswordFormData> = {
    password: passwordField(true),
    passwordConfirmation: {
      ...passwordConfirmationField,
      label: 'Confirm Password',
      validator: passwordConfirmationField.validator.oneOf(
        [yup.ref('password'), null],
        'Passwords must match',
      ),
    },
  };

  return (
    <>
      <Snackbar
        open={!!errorMessage || !!successMessage}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity={errorMessage ? 'error' : 'success'} onClose={handleClose}>
          {errorMessage || successMessage}
        </Alert>
      </Snackbar>
      <AuthLayout>
        <div className="text-center text-black-50 mb-3">
          <span>Please set a new password below:</span>
        </div>
        <FormContainer loading={loading} onSubmit={onSubmit} fields={fields} />
      </AuthLayout>
    </>
  );
};
