import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, makeStyles, PropTypes } from '@material-ui/core';
import { Moment } from 'moment';
import { DatePicker } from './date-picker/date-picker';
import { DateOptions } from './date-picker-options/date-picker-options-menu';

export interface DateOption {
  value: Moment;
  setValue: (value: Moment) => void;
}

/* eslint-disable-next-line */
export interface DateRangePickerProps {
  single?: boolean;
  color?: PropTypes.Color;
  startDate: DateOption;
  endDate: DateOption;
  onSelectOption: (startDate: Moment, endDate: Moment) => void;
  onClickGo?: () => void;
}

const useStyles = makeStyles((theme) => ({
  dateRangePicker: {
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  goButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const DateRangePicker = ({
  single = false,
  color = 'primary',
  startDate,
  endDate,
  onSelectOption,
  onClickGo,
}: DateRangePickerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.dateRangePicker}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          label={single ? 'Date' : 'From'}
          color={color}
          value={startDate.value}
          setValue={startDate.setValue}
        />

        <DatePicker label="To" color={color} value={endDate.value} setValue={endDate.setValue} />
      </MuiPickersUtilsProvider>
      <div>
        {onClickGo ? (
          <Button
            className={classes.goButton}
            variant="contained"
            color={color}
            size="small"
            onClick={() => onClickGo()}
          >
            Go
          </Button>
        ) : null}
        <DateOptions
          startDate={startDate}
          endDate={endDate}
          onSelectOption={onSelectOption}
          color={color}
        />
      </div>
    </div>
  );
};
