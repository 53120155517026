import { SortDirection } from '@material-ui/core';
import { AllKeys } from '../../types/helpers';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  let aField = a[orderBy];
  let bField = b[orderBy];

  if (!Number.isNaN(+aField) && !Number.isNaN(+bField)) {
    aField = (+aField as unknown) as T[keyof T];
    bField = (+bField as unknown) as T[keyof T];
  }

  if (bField < aField) {
    return -1;
  }
  if (bField > aField) {
    return 1;
  }
  return 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: SortDirection,
  orderBy: Key,
): (a: { [key in Key]: unknown }, b: { [key in Key]: unknown }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], sortDirection: SortDirection, orderBy: AllKeys<T>): T[] {
  const comparator = getComparator(sortDirection, orderBy);
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
