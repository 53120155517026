import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { MuiTheme } from './utils/theme/theme';
import { Router } from './components/router';
import './assets/scss/base.scss';

export const App: React.FunctionComponent = () => (
  <ThemeProvider theme={MuiTheme}>
    <CssBaseline />
    <Router />
  </ThemeProvider>
);
