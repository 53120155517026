import * as yup from 'yup';
import type { BidderAdapter } from '.';

const requirements = {
  connection: yup.object({
    dmxid: yup.string().required(),
    memberid: yup.string().required(),
  }),
  reporting: yup.object({
    reporting_username: yup.string().required(),
    reporting_password: yup.string().required(),
    memberId: yup.number().required(),
  }),
};

export const districtmdmx: BidderAdapter = {
  name: 'districtmdmx',
  requirements,
  getConnectionValues: (bidder) => {
    const formattedMetadata = JSON.parse(bidder.metadata);
    return {
      memberid: formattedMetadata.memberId,
    };
  },
  overallRequirements: ['memberid'],
};
