import React from 'react';
import { usePublishersQuery } from '@snapshot/data-access';
import { Backdrop, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { DataTableContainer } from '../../components/data-table/data-table-container';
import { useAppSelector } from '../../store';
import { DataTableContext } from '../../types/datatables';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    zIndex: theme.zIndex.drawer + 1,
    color: '#AAA',
    position: 'absolute',
  },
}));

export const PublishersPage = () => {
  const { data, loading } = usePublishersQuery();
  const { user } = useAppSelector(({ auth }) => auth);

  const classes = useStyles();

  const publishers = data?.publishers || [];

  const dataTableContext: DataTableContext = {
    moduleName: 'publisher',
    scene: 'manage',
    user,
  };

  const showTable = publishers.length;

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Publishers</h1>
      </Grid>
      <Grid item xs={12}>
        {showTable ? <DataTableContainer context={dataTableContext} data={publishers} /> : null}
        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
};
