import { combineReducers } from '@reduxjs/toolkit';
import { reducer as auth } from './authentication';
import { reducer as theme } from './theme/theme-slice';
import { reducer as dataTable } from './data-table/data-table-slice';

export const rootReducer = combineReducers({
  auth,
  theme,
  dataTable,
});

export type RootState = ReturnType<typeof rootReducer>;
